import React, { useState } from "react";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { Popconfirm } from "antd";

const ActivationCodeRequests = ({ std, handleAcceptReq, handleDeleteReq }) => {
  const AuthState = useAuthState();

  const [activationCode, setActivationCode] = useState("");

  return (
    <div className="btns flex items-center justify-center gap-7 flex-col w-1/2 md:w-full max-w-[300px]">
      <input
        className="signin-inputs signin-input  w-full "
        type="text"
        min={1}
        id="count"
        placeholder="Activation Code"
        name="count"
        autoComplete="on"
        value={activationCode}
        onChange={(e) => setActivationCode(e.target.value)}
      />
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("request_accept")
        )) && (
        <button
          onClick={() => handleAcceptReq(std.key, activationCode)}
          className="w-full approve-btn rounded-[100px] border-2 border-secondary bg-white px-10 py-5 text-center text-2xl text-secondary transition-all duration-300 hover:scale-105 hover:text-white hover:bg-logoYellow hover:border-logoYellow active:scale-90"
        >
          Approve
        </button>
      )}
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("request_delete")
        )) && (
        <Popconfirm
          okText="Confirm"
          okType="danger"
          cancelText="Cancel"
          color="#fecaca"
          //okButtonProps={{ loading: loading }}
          onConfirm={() => handleDeleteReq(std.key)}
          title="Do you really want to delete the student's request?"
        >
          <button className="w-full approve-btn rounded-[100px] border-2 border-[#d93223] bg-white px-10 py-5 text-center text-2xl text-[#d93223] transition-all duration-300 hover:scale-105 hover:text-white  hover:text-[#d93223] hover:bg-logoYellow hover:border-logoYellow active:scale-90">
            Delete
          </button>
        </Popconfirm>
      )}
    </div>
  );
};

export default ActivationCodeRequests;
