import useTable from "../../../../MainComponents/Hooks/useTable";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useParams } from "react-router-dom";

function AdminReservationsStudents() {
  const { reservationID } = useParams();
  const table = useTable("reservationStudent", "", "", "", "", reservationID);
  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title={`Reservation Student | Nawar Academy`}></HelmetTags>
      <h2 className="w-full text-4xl font-bold md:text-center">
        Reservation Students
      </h2>

      {/**
       * //!---------Table-----
       */}

      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default AdminReservationsStudents;
