import { useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { Loader } from "../../../../MainComponents";

function BlockedStudents() {
  const [reFetch, setReFetch] = useState(false);

  const [
    getAllBlockedStudents,
    getAllBlockedStudentsErrors,
    getAllBlockedStudentsLoading,
  ] = useAxios(
    `${process.env.REACT_APP_ADMIN_GET_BLOCKED_STUDENT_API}`,
    "GET",
    "GET",
    reFetch
  );
  const table = useTable(
    "blockedStudents",
    false,
    "",
    getAllBlockedStudents?.data,
    reFetch,
    "",
    true,
    getAllBlockedStudents?.data,
    reFetch,
    setReFetch
  );

  if (getAllBlockedStudentsLoading) return <Loader />;

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title="Blocked Students | Nawar Academy"></HelmetTags>{" "}
      <h2 className="w-full text-4xl font-bold md:text-center mb-12">
        Blocked Students: {getAllBlockedStudents?.data?.length}
      </h2>
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default BlockedStudents;
