import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Amount = () => {
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  //!--------- add edit assistant -------

  const [RechargeAmountSuccess, RechargeAmountErrors, RechargeAmountLoading] =
    useAxios(
      process.env.REACT_APP_RECHARGE_AMOUNT_STUDENT_API,
      "POST",
      formFlag,
      formDependency,
      formValues
    );

  const onSubmit = (formData) => {
    setFormValues(formData);
    setFormFlag("recharge");
    setFormDependency(true);
  };

  useEffect(() => {
    if (RechargeAmountSuccess) {
      toast.success("Update Amount Successfully..");
      setFormFlag("");
      setFormDependency(false);
    }
  }, [RechargeAmountSuccess]);

  useEffect(() => {
    if (RechargeAmountErrors) {
      toast.error(RechargeAmountErrors);
      setFormFlag("");
      setFormDependency(false);
    }
  }, [RechargeAmountErrors]);

  return (
    <section className="h-auto width flex flex-col items-center">
      <div className="h-auto width flex flex-col items-center my-20">
        <h4 className="text-3xl font-bold">Recharge Amount</h4>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col justify-center items-start  bg-white p-16  rounded-3xl shadow-lg shadow-blue/50 w-3/4 my-6 gap-6"
        >
          {/** code  */}

          <div className="w-full">
            <div className="relative flex flex-col justify-center items-start gap-2 ">
              <label htmlFor="code">Code</label>
              <input
                id="code"
                className="signin-inputs signin-input w-full"
                type="text"
                placeholder="code"
                name="code"
                autoComplete="on"
                {...register("code", {
                  required: true,
                })}
              />
            </div>
          </div>

          <button
            className=" submit mt-6 w-full "
            type="submit"
            disabled={RechargeAmountLoading}
          >
            {RechargeAmountLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Recharge"
            )}
          </button>
        </form>
      </div>
    </section>
  );
};

export default Amount;
