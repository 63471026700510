import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useState } from "react";
import useTable from "../../../../MainComponents/Hooks/useTable";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useParams } from "react-router-dom";
import { Loader } from "../../../../MainComponents";

function CenterStudentsGroupFilter() {
  const { stageId, groupId } = useParams();
  const [reFetch, setReFetch] = useState(false);

  const [allGroups, allGroupsErrors, allGroupsLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_STUDENTS_TAB_API}${stageId}`,
    "GET",
    "GET",
    "",
    "",
    false,
    reFetch
  );
  const table = useTable(
    "students",
    "",
    "",
    allGroups,
    "",
    "",
    true,
    allGroups?.data?.filter((data) => +data.group_id === +groupId) || [],
    reFetch,
    setReFetch
  );

  if (allGroupsLoading) {
    return <Loader />;
  }
  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Center Students | Nawar Academy"></HelmetTags>

      <h1 className="w-full text-4xl font-bold md:text-center">
        Students Number:{" "}
        {allGroups?.data?.filter((data) => +data.group_id === +groupId)?.length}
      </h1>

      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default CenterStudentsGroupFilter;
