import {
  faEye,
  faEyeSlash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";

const StudentChangePassword = () => {
  const [hide, setHide] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);
  const { assistantID } = useParams("");
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  //!--------- add edit assistant -------

  const [
    EditStudentPasswordSuccess,
    EditStudentPasswordErrors,
    EditStudentPasswordLoading,
  ] = useAxios(
    process.env.REACT_APP_EDIT_STUDENT_PROFILE_PASSWORD_API,
    "POST",
    formFlag,
    formDependency,
    formValues,
    true
  );

  const onSubmit = (formData) => {
    setFormValues({ id: assistantID, ...formData });
    setFormFlag("EditAssistantPassword");
    setFormDependency(true);
  };

  useEffect(() => {
    if (EditStudentPasswordSuccess) {
      toast.success("Update Password Successfully..");
      setFormFlag("");
      setFormDependency(false);
    }
  }, [EditStudentPasswordSuccess]);

  useEffect(() => {
    if (EditStudentPasswordErrors) {
      setFormFlag("");
      setFormDependency(false);
    }
  }, [EditStudentPasswordErrors]);

  return (
    <div className="h-auto width flex flex-col items-center mb-20">
      <h4 className="text-3xl font-bold">Modify password</h4>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-center items-start  bg-white p-16  rounded-3xl shadow-lg shadow-blue/50 w-3/4 my-6 gap-6"
      >
        {/** Password  */}

        <div className="w-full">
          <div className="relative flex flex-col justify-center items-start gap-2 ">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              className="signin-inputs signin-input w-full"
              type={`${hide ? "text" : "password"}`}
              placeholder="Password"
              name="password"
              autoComplete="on"
              {...register("password", {
                required: true,
                maxLength: 25,
                minLength: 6,
              })}
            />
            {hide ? (
              <FontAwesomeIcon
                onClick={() => setHide(!hide)}
                className="absolute right-4 bottom-5  w-8 h-8 cursor-pointer"
                icon={faEye}
              />
            ) : (
              <FontAwesomeIcon
                onClick={() => setHide(!hide)}
                className="absolute right-4  bottom-5  w-8 h-8 cursor-pointer"
                icon={faEyeSlash}
              />
            )}
          </div>
          {errors.password && (
            <p className="text-blue-500 text-xl pt-4">
              {errors.password.type === "required" && "This field is required."}
              {errors.password.type === "maxLength" &&
                "password Max length is 25 char."}
              {errors.password.type === "minLength" &&
                "Password Min length is 6 char."}
            </p>
          )}
        </div>

        {/** Confirm Password  */}

        <div className="w-full">
          <div className="relative flex flex-col justify-center items-start gap-2 ">
            <label htmlFor="password_confirmation">Confirm password</label>
            <input
              id="password_confirmation"
              className="signin-inputs signin-input w-full"
              type={`${hide ? "text" : "password"}`}
              placeholder="Confirm password"
              name="password_confirmation"
              autoComplete="on"
              {...register("password_confirmation", {
                required: true,
                maxLength: 25,
                minLength: 6,
                validate: (value) => value === getValues("password"),
              })}
            />
            {hide ? (
              <FontAwesomeIcon
                onClick={() => setHide(!hide)}
                className="absolute right-4 bottom-5  w-8 h-8 cursor-pointer"
                icon={faEye}
              />
            ) : (
              <FontAwesomeIcon
                onClick={() => setHide(!hide)}
                className="absolute right-4 bottom-5  w-8 h-8 cursor-pointer"
                icon={faEyeSlash}
              />
            )}
          </div>
          {errors.password_confirmation && (
            <p className="text-blue-500 text-xl pt-4">
              {errors.password_confirmation.type === "required" &&
                "This field is required."}
              {errors.password_confirmation.type === "maxLength" &&
                "password_confirmation Max length is 25 char."}
              {errors.password_confirmation.type === "minLength" &&
                "Password Min length is 6 char."}
              {errors.password_confirmation.type === "validate" &&
                "Passwords don't match."}
            </p>
          )}
        </div>

        {/* <button className=" submit mt-6 w-full " type="submit">
          {EditStudentPasswordLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            "تعديل"
          )}
        </button> */}
        <input className=" submit max-w-60 mt-10" type="submit" value="Edit" />
      </form>
    </div>
  );
};

export default StudentChangePassword;
