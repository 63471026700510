import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faAddressCard,
  faBuildingUser,
  faChalkboardUser,
  faLocationDot,
  faMobileScreenButton,
  faSchoolFlag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Popconfirm } from "antd";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import {
  useAuthState,
  useAuthDispatch,
} from "../../../../MainComponents/GlobalContext";
import { Loader } from "../../../../MainComponents";
import ActivationCodeRequests from "./ActivationCodeRequests.jsx";

function Requests() {
  const AuthDispatch = useAuthDispatch();

  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [submitAccept, setSubmitAccept] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [reFetch, setReFetch] = useState(false);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [searchedInput, setSearchedInput] = useState("");
  const [academiclevel, setAcademiclevel] = useState(1);
  const [left, setLeft] = useState("left-0");
  const [numberOfData, setNumberOfData] = useState(50);

  //!--------- all Requests request -------
  const [allRequestsData, allRequestsErrors, loading] = useAxios(
    `${process.env.REACT_APP_ALL_REQUESTS_API}/${academiclevel}`,
    "GET",
    "GET",
    academiclevel,
    "",
    false,
    reFetch
  );
  //!--------- delete Request -------

  const [deleteRequestSuccess, deleteRequestErrors] = useAxios(
    process.env.REACT_APP_DELETE_REQUEST_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  //!--------- accept Request -------

  const [acceptRequestSuccess, acceptRequestErrors] = useAxios(
    process.env.REACT_APP_ACCEPT_REQUEST_API,
    "POST",
    submitAccept.flag,
    submitAccept.dependency,
    submitAccept.data,
    true
  );
  useEffect(() => {
    //! refetch the data on success delete or accept request

    if (deleteRequestSuccess || acceptRequestSuccess) {
      setReFetch(!reFetch);
      AuthDispatch({
        type: "setRefetchIfAcceptOrDeleteRequest",
      });
    }
  }, [deleteRequestSuccess, acceptRequestSuccess]);

  useEffect(() => {
    if (allRequestsData) {
      setFilteredRequests(allRequestsData?.data);
    }
  }, [allRequestsData]);

  function handleDeleteReq(id) {
    setSubmitDelete({
      flag: "deleteRequest",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }
  function handleAcceptReq(id, activationCode) {
    setSubmitAccept({
      flag: "acceptRequest",
      dependency: !submitAccept.dependency,
      data: { id: id, activation_code: activationCode },
    });
  }

  const handleSearche = (e) => {
    setSearchedInput(e.target.value);

    const filterdData = allRequestsData?.data.filter((prev) =>
      prev.mobile.startsWith(e.target.value)
    );

    setFilteredRequests(filterdData);
  };

  const handleChangeacAdemicLevel = (position, acadimcNum) => {
    setLeft(position);
    setAcademiclevel(acadimcNum);
  };

  const handleLoadMore = () => {
    setNumberOfData((prev) => prev + 50);
  };

  const displayedRequests = filteredRequests.slice(0, numberOfData);

  return (
    <>
      <HelmetTags title="Requests | Nawar Academy"></HelmetTags>

      <section className="h-fit w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
        <h2 className="mb-16 w-full  text-4xl font-bold md:text-center">
          Inactive students
        </h2>

        <div
          className={`filter relative flex  h-10 min-h-[40px] w-1/2 md:w-full justify-start  mb-10`}
        >
          <button
            onClick={() => handleChangeacAdemicLevel("left-o", 1)}
            className="z-10 h-full w-1/3 text-3xl font-semibold "
          >
            Stage 1
          </button>
          <button
            onClick={() => handleChangeacAdemicLevel("left-1/3", 2)}
            className="z-10 h-full w-1/3 text-3xl font-semibold "
          >
            Stage 2
          </button>
          <button
            onClick={() => handleChangeacAdemicLevel("left-2/3", 3)}
            className="z-10 h-full w-1/3 text-3xl font-semibold "
          >
            Stage 3
          </button>
          <div
            className={`std-selector absolute top-0 h-full w-1/3 ${left} rounded-md bg-green duration-300`}
          ></div>
        </div>

        <div className="search-wrapper w-1/2 md:w-full mb-8">
          <input
            type="search"
            className="signin-inputs signin-input px-4 placeholder:!text-3xl"
            placeholder="Search..."
            onChange={(e) => handleSearche(e)}
            value={searchedInput}
          />
        </div>

        {loading ? (
          <Loader />
        ) : displayedRequests?.length === 0 ? (
          <p className="w-full text-center">No new requests</p>
        ) : allRequestsErrors ? (
          <p className="w-full text-center">{allRequestsErrors}</p>
        ) : (
          <>
            {displayedRequests?.map((std) => (
              <div
                key={std.key}
                //!single student
                className="mx-auto mb-20 flex  h-fit  items-center justify-between gap-12 rounded-3xl border-2 border-secondary  p-6 w-full md:flex-col md:gap-16"
              >
                <div className="flex-2 std-img flex gap-4 md:flex-col  h-96 overflow-hidden rounded-xl ">
                  <div className="h-full w-full rounded-xl object-cover object-top">
                    <img
                      className="h-full w-full rounded-xl border-2 border-secondary object-cover object-top "
                      src={std.profile_pic}
                      alt={std.name}
                    />
                  </div>
                  {+std?.stage === 3 && (
                    <div className="h-full w-full rounded-xl object-cover object-top">
                      <img
                        className="h-full w-full rounded-xl border-2 border-secondary object-cover object-top "
                        src={std.certificate_pic}
                        alt={std.name}
                      />
                    </div>
                  )}
                </div>
                <div
                  //!std-left
                  className="flex-1 std-details flex flex-col h-full items-center justify-evenly  gap-5   sm:gap-14 sm:p-10"
                >
                  <div className="std-info flex h-auto w-fit flex-col justify-start gap-4 ">
                    <div className="flex justify-start">
                      {/* <div className="w-8 min-w-[32px]  text-3xl ">
                      <FontAwesomeIcon
                        className=""
                        icon={faTitle}
                      />
                    </div> */}
                      <h4 className=" text-[17px] font-semibold ">
                        Name:{" "}
                        <span className="text-[15px] opacity-80">
                          {std.name}
                        </span>
                      </h4>
                    </div>
                    <div className="flex justify-start">
                      {/* <div className="w-8 min-w-[32px]  text-3xl ">
                      <FontAwesomeIcon
                        className=""
                        icon={faTitle}
                      />
                    </div> */}
                      <h4 className=" text-[17px] font-semibold ">
                        Stage:
                        <span className="text-[15px] opacity-80">
                          {std.stage}
                        </span>
                      </h4>
                    </div>
                    <div className="flex justify-start">
                      <div className="w-8 min-w-[32px]  text-3xl ">
                        <FontAwesomeIcon
                          className=""
                          icon={faMobileScreenButton}
                        />
                      </div>
                      <h4 className=" text-[17px] font-semibold ">
                        Phone:{" "}
                        <span className="text-[15px] opacity-80">
                          {std.mobile}
                        </span>
                      </h4>
                    </div>

                    <div className="flex justify-start">
                      <div className="w-8 min-w-[32px]  text-3xl ">
                        <FontAwesomeIcon
                          className=""
                          icon={faMobileScreenButton}
                        />
                      </div>
                      <h4 className=" text-[17px] font-semibold ">
                        Father Mobile:
                        <span className="text-[15px] opacity-80 xs:text-center">
                          {std.parent_mobile}
                        </span>
                      </h4>
                    </div>
                    <div className="flex justify-start">
                      <div className="w-8 min-w-[32px]  text-3xl ">
                        <FontAwesomeIcon
                          className=""
                          icon={faMobileScreenButton}
                        />
                      </div>
                      <h4 className=" text-[17px] font-semibold ">
                        Mother Mobile:
                        <span className="text-[15px] opacity-80 xs:text-center">
                          {std.parent_mobile2}
                        </span>
                      </h4>
                    </div>
                    <div className="flex justify-start">
                      <div className="w-8 min-w-[32px] text-3xl">
                        <FontAwesomeIcon className="" icon={faLocationDot} />
                      </div>
                      <div className=" text-[17px] font-semibold">
                        <h5 className="inline-block">City: </h5>
                        <span className="text-[15px] opacity-80">
                          {std.area}
                        </span>
                      </div>
                    </div>

                    <div className="flex justify-start">
                      <div className="w-8 min-w-[32px] text-3xl">
                        <FontAwesomeIcon
                          className=""
                          icon={
                            std.is_online === "Center"
                              ? faBuildingUser
                              : faChalkboardUser
                          }
                        />
                      </div>
                      <div className=" text-[17px] font-semibold ">
                        <h5 className="inline-block">Place: </h5>
                        <span className="text-[15px] opacity-80">
                          {std.is_online}
                        </span>
                      </div>
                    </div>
                    {std.section && (
                      <div className="flex justify-start">
                        <div className=" text-[17px] font-semibold ">
                          <h5 className="inline-block">Department: </h5>
                          <span className="text-[15px] opacity-80">
                            {std.section}
                          </span>
                        </div>
                        <div className="w-8 min-w-[32px]   text-3xl ">
                          <FontAwesomeIcon className="" icon={faAddressCard} />
                        </div>
                      </div>
                    )}
                    <div className="flex justify-start">
                      <div className="w-8 min-w-[32px]   text-3xl ">
                        <FontAwesomeIcon className="" icon={faSchoolFlag} />
                      </div>
                      <div className=" text-[17px] font-semibold ">
                        <h5 className="inline-block">School: </h5>
                        <span className="text-[15px] opacity-80">
                          {std.school}
                        </span>
                      </div>
                    </div>

                    <div className="flex justify-start">
                      <div className="w-8 min-w-[32px]  text-3xl ">
                        <FontAwesomeIcon className="" icon={faClock} />
                      </div>
                      <h4 className=" text-[17px] font-semibold ">
                        Release Date:
                        <span className="text-[15px] opacity-80">
                          {std.created_at}
                        </span>
                      </h4>
                    </div>
                  </div>
                  <ActivationCodeRequests
                    std={std}
                    handleAcceptReq={handleAcceptReq}
                    handleDeleteReq={handleDeleteReq}
                  />
                </div>
              </div>
            ))}
            {filteredRequests.length > numberOfData && (
              <div className="text-center my-8">
                <button
                  onClick={handleLoadMore}
                  className="load-more-btn rounded-[100px] border-2 border-secondary bg-white px-10 py-5 text-center text-2xl text-secondary transition-all duration-300 hover:scale-105 hover:text-white hover:bg-logoYellow hover:border-logoYellow active:scale-90"
                >
                  Load More
                </button>
              </div>
            )}
          </>
        )}
      </section>
    </>
  );
}

export default Requests;
