//!you may need to change record.id to record.key
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useTable from "../../../../MainComponents/Hooks/useTable";
import toast from "react-hot-toast";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";

function AdminRevisionCodes() {
  const { lectureID } = useParams();
  const [submitGenerate, setSubmitGenerate] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [generateCodesSuccess, generateCodesErrors, submitLoading] = useAxios(
    process.env.REACT_APP_ADMIN_GENERATE_REVISION_CODE_API,
    "POST",
    submitGenerate.flag,
    submitGenerate.dependency,
    submitGenerate.data,
    true
  );
  const table = useTable(
    "revisionCodes",
    "",
    "",
    generateCodesSuccess?.data,
    "",
    lectureID
  );
  const [border, setBorder] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    setSubmitGenerate({
      flag: "generateCodesRequest",
      dependency: !submitGenerate.dependency,
      data: { ...data, lecture_id: lectureID },
    });
  };
  useEffect(() => {
    //! reset the form on success submit

    if (generateCodesSuccess) {
      setBorder(true);
      reset();
    }
  }, [generateCodesSuccess]);

  async function copy(code) {
    try {
      await navigator.clipboard.writeText(code);
      toast.success("Copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
      toast.error("Copying to clipboard failed. Please try again.");
    }
  }

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title={`Revision Codes | Nawar Academy`}></HelmetTags>
      <h2 className="w-full text-4xl font-bold md:text-center">
        Revision Codes
      </h2>

      <div className="form-codes-container my-20">
        <h2 className="my-20 text-center text-3xl font-semibold">
          Please fill in the data to extract Revision Codes
        </h2>
        <div className="form-codes flex w-full  items-start justify-center gap-20 lg:flex-col lg:items-center lg:justify-start">
          <form
            method="post"
            onSubmit={handleSubmit(onSubmit)}
            className="flex h-fit  w-1/2 md:w-full flex-col  items-center justify-start gap-6 rounded-3xl   p-10 shadow-lg shadow-red-200/30 md:w-[250px] "
          >
            <div className="first-row flex flex-row-reverse w-full items-center justify-between gap-6 md:flex-col md:justify-start">
              {/** number of codes field */}

              <div
                className={` flex flex-col items-end justify-center gap-2 md:w-full`}
              >
                <label className="w-full truncate text-start" htmlFor="count">
                  Number of codes
                </label>
                <input
                  className="signin-inputs signin-input  w-full "
                  type="number"
                  min={1}
                  id="count"
                  placeholder="000"
                  name="count"
                  autoComplete="on"
                  {...register("count", {
                    required: true,
                    valueAsNumber: true,
                  })}
                />

                {errors.count && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors.count.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  generateCodesErrors &&
                    generateCodesErrors?.response?.data?.errors?.count && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {generateCodesErrors?.response?.data?.errors?.count[0]}
                      </p>
                    )
                }
              </div>
              <div className=" flex w-1/2 flex-col items-end justify-center gap-2 md:w-full">
                <label className="w-full truncate text-start" htmlFor="length">
                  Number of code characters
                </label>
                <input
                  className="signin-inputs signin-input  pl-4 w-full "
                  type="number"
                  min={1}
                  max={255}
                  id="length"
                  placeholder="000"
                  name="length"
                  autoComplete="on"
                  {...register("length", {
                    required: true,
                    valueAsNumber: true,
                    max: 255,
                    min: 1,
                  })}
                />

                {errors.length && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors.length.type === "required" &&
                      "Please fill out this field"}
                    {errors.length.type === "max" &&
                      "The maximum number of characters is 225 characters"}
                    {errors.length.type === "min" &&
                      "Please write at least one letter"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  generateCodesErrors &&
                    generateCodesErrors?.response?.data?.errors?.length && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {generateCodesErrors?.response?.data?.errors?.length[0]}
                      </p>
                    )
                }
              </div>
            </div>
            <div
              className={` flex w-full flex-col items-end justify-center gap-2 md:w-full`}
            >
              <label
                className="w-full truncate text-start"
                htmlFor="expire_after"
              >
                Expiration Days
              </label>
              <input
                className="signin-inputs signin-input  w-full "
                type="number"
                min={1}
                id="expire_after"
                placeholder="000"
                name="expire_after"
                autoComplete="on"
                {...register("expire_after", {
                  required: true,
                  valueAsNumber: true,
                })}
              />

              {errors.expire_after && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.expire_after.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                generateCodesErrors &&
                  generateCodesErrors?.response?.data?.errors?.expire_after && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {
                        generateCodesErrors?.response?.data?.errors
                          ?.expire_after[0]
                      }
                    </p>
                  )
              }
            </div>
            <div
              className={` flex w-full flex-col items-end justify-center gap-2 md:w-full`}
            >
              <label className="w-full truncate text-start" htmlFor="views">
                Views
              </label>
              <input
                className="signin-inputs signin-input  w-full "
                type="number"
                min={1}
                id="views"
                placeholder="000"
                name="views"
                autoComplete="on"
                {...register("views", {
                  required: true,
                  valueAsNumber: true,
                })}
              />

              {errors.views && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.views.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                generateCodesErrors &&
                  generateCodesErrors?.response?.data?.errors?.views && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {generateCodesErrors?.response?.data?.errors?.views[0]}
                    </p>
                  )
              }
            </div>
            <div
              className={`flex flex-col items-end justify-center gap-2 w-full  md:w-full`}
            >
              <label className="w-full truncate" htmlFor="type">
                Type of codes
              </label>
              <select
                name="type"
                id="type"
                {...register("type", { required: true })}
              >
                <option value="char">Letters only</option>
                <option value="numbers">Numbers only</option>
                <option value="mixed">Letters and numbers</option>
              </select>

              {errors.type && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.type.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                generateCodesErrors &&
                  generateCodesErrors?.response?.data?.errors?.type && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {generateCodesErrors?.response?.data?.errors?.type[0]}
                    </p>
                  )
              }
            </div>
            {/** submit */}
            <button
              disabled={!isValid || (submitLoading && submitGenerate.flag)}
              className=" submit mt-6 w-full "
              type="submit"
            >
              {submitLoading && submitGenerate.flag ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "Extract"
              )}
            </button>
          </form>
          {generateCodesSuccess?.data && (
            <div className="flex flex-col items-center gap-4">
              <button className="approve-btn rounded-[100px] border-2 border-secondary px-10 py-5 text-center text-2xl text-secondary transition-all duration-300 hover:scale-105 hover:text-white hover:bg-logoYellow hover:border-logoYellow active:scale-90">
                <CSVLink
                  data={generateCodesSuccess?.data}
                  filename={`Generated Code Excel.csv`}
                >
                  Export
                </CSVLink>
              </button>
              <div
                className={`codes flex h-auto max-h-[380px] w-[320px] flex-wrap items-center justify-center gap-7 overflow-y-auto p-4 md:w-[250px] ${
                  border ? "rounded-xl border-2 border-secondary" : ""
                } `}
              >
                {generateCodesSuccess?.data?.map((code) => (
                  <button className="code-btn" onClick={() => copy(code.code)}>
                    {code.code}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {/**
       * //!---------Table-----
       */}

      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default AdminRevisionCodes;
