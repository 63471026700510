import { Link, useParams } from "react-router-dom";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";

function AdminLecture({ course, revision }) {
  const AuthState = useAuthState();
  const { lectureID, courseID } = useParams();
  const [reFetch, setReFetch] = useState(false);
  // const [allData, setAllData] = useState([]);

  const [lectureInfoData, lectureInfoErrors, lectureInfoloading] = useAxios(
    course
      ? `${process.env.REACT_APP_ADMIN_VIEW_LECTURE_TAB_API}/${courseID}`
      : `${process.env.REACT_APP_ADMIN_VIEW_LECTURE_TAB_API}/${lectureID}`,
    "GET",
    "GET",
    lectureID | courseID,
    "",
    "",
    reFetch
  );

  const table = useTable(
    course ? "courses" : "lectures",
    false,
    "",
    lectureInfoData?.data,
    "",
    "",
    true,
    lectureInfoData?.data,
    reFetch,
    setReFetch
  );

  // const table = useTable(
  //   "",
  //   "",
  //   "",
  //   "",
  //   lectureID | courseID
  // );

  const attendedByAssistantsNumber = lectureInfoData?.data.filter(
    (data) => data.added_by !== null
  ).length;

  const attendedByCenterNumber = lectureInfoData?.data.filter(
    (data) => data.method === "center"
  ).length;

  const attendedByCodeNumber =
    lectureInfoData?.data.length -
    (attendedByAssistantsNumber + attendedByCenterNumber);

  if (lectureInfoloading) {
    return <Loader />;
  }
  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags
        title={`${
          course ? "Course" : revision ? "Revision" : "Lecture"
        } | Nawar Academy`}
      ></HelmetTags>
      <div className="mb-20 flex flex-col items-start gap-20">
        <div className="top-btns flex w-full justify-start gap-10 asm:flex-col asm:items-center asm:justify-start asm:gap-14">
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture_addvideos")
            )) && (
            <Link
              to={`/admin/${
                course ? "courses" : revision ? "revisions" : "lectures"
              }/sessions/${lectureID | courseID}/videos`}
              className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Videos
            </Link>
          )}
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture_addmatrial")
            )) && (
            <Link
              to={`/admin/${
                course ? "courses" : revision ? "revisions" : "lectures"
              }/sessions/${lectureID | courseID}/materials`}
              className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Attchments
            </Link>
          )}

          {!course && (
            <>
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addHw")
                )) && (
                <Link
                  to={`/admin/${
                    course ? "courses" : revision ? "revisions" : "lectures"
                  }/sessions/${lectureID | courseID}/homework`}
                  className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
                >
                  <FontAwesomeIcon className="mr-4" icon={faPlus} />
                  Homework
                </Link>
              )}
            </>
          )}

          {!course && (
            <>
              {" "}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addquiz")
                )) && (
                <Link
                  to={`/admin/${
                    course ? "courses" : revision ? "revisions" : "lectures"
                  }/sessions/${lectureID | courseID}/quiz`}
                  className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
                >
                  <FontAwesomeIcon className="mr-4" icon={faPlus} />
                  Quiz
                </Link>
              )}
            </>
          )}
        </div>
        <hr className="w-3/4 border-[1px] border-secondary" />
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_addstudent")
          )) && (
          <div className="bottom-btn flex w-full justify-start asm:justify-center">
            <Link
              to={`/admin/${
                course ? "courses" : revision ? "revisions" : "lectures"
              }/sessions/${lectureID | courseID}/add-student`}
              className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add Student
            </Link>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-1 mb-6">
        <h4 className="text-2xl mb-3 font-bold md:text-center">
          Attended By Assistants: {attendedByAssistantsNumber}
        </h4>
        <h4 className="text-2xl mb-3 font-bold md:text-center">
          Attended By Code: {attendedByCodeNumber}
        </h4>
        <h4 className="text-2xl mb-3 font-bold md:text-center">
          Attended In Center: {attendedByCenterNumber}
        </h4>
      </div>
      {/**
       * //!---------Table-----
       */}
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_view")
        )) && <div className="h-fit  w-full">{table}</div>}
    </section>
  );
}

export default AdminLecture;
