import { useParams } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { Loader } from "../../../../MainComponents";
import useAxios from "../../../../MainComponents/Hooks/useAxios";

function SessionAbsence() {
  const params = useParams();
  // const table = useTable("sessionAbsence", "", "", "", "", params);

  const [allAbsence, allAbsenceErrors, allAbsenceLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_SESSICONS_ABSENCE_API}${params.groupID}/${params.lectureID}`,
    "GET",
    "GET",
    ""
  );

  const table = useTable(
    "sessionAbsence",
    "",
    "",
    allAbsence,
    "",
    "",
    true,
    allAbsence?.data
  );

  if (allAbsenceLoading) return <Loader />;
  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Absence | Nawar Academy"></HelmetTags>

      <div className="mb-20 flex flex-col items-end gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">
          Absence Number: {allAbsence?.data?.length}
        </h2>
        <div className="h-fit  w-full">{table}</div>
      </div>
    </section>
  );
}

export default SessionAbsence;
