import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import "swiper/css";

import App from "./App";
import { Toaster } from "react-hot-toast";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "./MainComponents/GlobalContext.jsx";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <HelmetProvider>
    <Toaster position="top-center" reverseOrder={false} />
    <AuthProvider>
      <App />
    </AuthProvider>
  </HelmetProvider>
  /*  <React.StrictMode>
  </React.StrictMode> */
);
