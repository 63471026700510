import React from "react";

export const DisplayQuestion = ({ state, children }) => {
  return (
    <div
      className={`left-question-container questions-section flex min-h-full w-full flex-col  items-center px-[5%] py-[40px] md:w-full ${
        state.response && "hidden"
      }`}
    >
      {children}
    </div>
  );
};
