import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { toast } from "react-hot-toast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor as ClassicEditor } from "ckeditor5-custom-build/build/ckeditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
function AddEditPost({ edit, setReFetch, reFetch }) {
  const [content, setContent] = useState("");
  const [stage, setStage] = useState("");
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();
  const { postID } = useParams();

  const [submitContent, setSubmitContent] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  //!  reset error messages if the input value has changed
  useEffect(() => {
    if (content !== "") {
      setErrors("");
    } else if (stage !== "") {
      setErrors("");
    }
  }, [content, stage]);

  //!---add edit content request
  let api = edit
    ? process.env.REACT_APP_EDIT_DASHBOARD_POST_API
    : process.env.REACT_APP_ADD_DASHBOARD_POST_API;
  const [addEditContentSuccess, addEditContentErrors, submitLoading] = useAxios(
    api,
    "POST",
    submitContent.flag,
    submitContent.dependency,
    submitContent.data,
    true
  );

  //!---get content data request

  const [ContentDataSuccess, ContentErrors] = useAxios(
    `${process.env.REACT_APP_DASHBOARD_POST_INFO_API}${postID}`,
    "GET",
    edit
  );
  useEffect(() => {
    if (ContentDataSuccess) {
      setStage(ContentDataSuccess.data?.stage);
      setContent(ContentDataSuccess.data?.content);
    }
  }, [ContentDataSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (content === "") {
      setErrors("content");
      toast.error("Please Add Content");
    } else if (stage === "") {
      setErrors("stage");
      toast.error("Please Add Stage");
    } else {
      const data = edit
        ? {
            content: content,
            stage: stage,
            id: postID,
          }
        : {
            content: content,
            stage: stage,
          };

      setSubmitContent({
        flag: "addEditContentRequest",
        dependency: !submitContent.dependency,
        data: data,
      });
    }
  };
  useEffect(() => {
    //! reset the form on success submit

    if (addEditContentSuccess) {
      setContent("");
      setStage("");

      setErrors("");
      edit ? navigate(-1) : setReFetch(!reFetch);
    }
  }, [addEditContentSuccess]);

  return (
    <section
      className={`flex h-auto w-full flex-col items-center dark:bg-dark dark:text-light pt-16`}
    >
      <form
        method="post"
        onSubmit={handleSubmit}
        className="form-container  flex flex-col items-center"
      >
        <h2 className={` text-center text-3xl mb-14`}>
          {edit
            ? "Please fill out the information to edit the post."
            : "Please fill out the information to add the post."}
        </h2>
        {/**
         * //!------content-----
         */}
        <div className=" content mb-10 flex w-[900px] flex-col gap-2 xsl:w-[700px] amd:w-[500px]  sm:w-[300px]">
          <h2 className="mb-3  font-semibold">Post</h2>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },
              language: "en",
              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={content}
            onChange={(event, editor) => {
              const data = editor.getData();
              setContent(data);
            }}
          />
          {errors === "content" && (
            <p className="w-full  text-[12px] text-red-900  ">
              {" "}
              Please fill out this field.{" "}
            </p>
          )}
          {
            //!-------server errors -----

            addEditContentErrors &&
              addEditContentErrors?.response?.data?.errors?.content && (
                <p className="w-full  text-[12px] text-red-900  ">
                  {addEditContentErrors?.response?.data?.errors?.content[0]}
                </p>
              )
          }
        </div>

        {/** //!---Stage degree
         *
         *       */}
        <div className="flex w-full items-start md:justify-evenly gap-10 md:gap-6 md:flex-col mt-5">
          <div
            className={`my-1 flex w-1/2 flex-col items-end justify-center gap-4  md:w-full `}
          >
            <label
              className="w-full truncate  font-semibold text-start"
              htmlFor="stage"
            >
              Class
            </label>
            <select
              className="text-start"
              value={stage}
              onChange={(e) => setStage(e.target.value)}
              name="stage"
              id="stage"
            >
              <option hidden disabled value="">
                Class
              </option>
              <option value="1">First</option>
              <option value="2">Second</option>
              <option value="3">Third</option>
            </select>

            {errors === "stage" && (
              <p className="w-full  text-[12px] text-red-900  ">
                {" "}
                Please fill out this field.{" "}
              </p>
            )}
            {
              //!-------server errors -----

              addEditContentErrors &&
                addEditContentErrors?.response?.data?.errors?.stage && (
                  <p className="w-full  text-[12px] text-red-900  ">
                    {addEditContentErrors?.response?.data?.errors?.stage[0]}
                  </p>
                )
            }
          </div>
        </div>
        <button
          disabled={submitLoading && submitContent.flag}
          className=" submit mt-6 w-full "
          type="submit"
        >
          {submitLoading && submitContent.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <p> {edit ? "Edit post" : "Add post"}</p>
          )}
        </button>
      </form>
    </section>
  );
}

export default AddEditPost;
