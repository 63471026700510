import { useRef } from "react";
import useFade from "../../../MainComponents/Hooks/useFade";
import SingleCourse from "../../../MainComponents/SingleCourse";
import SingleChapter from "../../../MainComponents/SingleChapter";

import useAxios from "../../../MainComponents/Hooks/useAxios";
import Loader from "../../../MainComponents/Loader";
import HelmetTags from "../../../MainComponents/HelmetTags";

function StudentLectures({ course, revision }) {
  //!---- get all lectures  -------

  const [allLecturesData, allLecturesErrors, allLecturesLoading] = useAxios(
    process.env.REACT_APP_STUDENT_LECTURES_API,
    "GET",
    "GET"
  );

  const TitleWrapper = useRef();
  const [fade] = useFade(TitleWrapper, 100);

  const data = course
    ? allLecturesData?.data
        ?.filter((course) => course.is_course === 1)
        .filter((course) => course.is_revision === 0)
    : revision
      ? allLecturesData?.data
          ?.filter((course) => course.is_course !== 1)
          .filter((course) => course.is_revision === 1)
      : allLecturesData?.data
          ?.filter((course) => course.is_course !== 1)
          .filter((course) => course.is_revision === 0);

  if (allLecturesLoading) {
    return <Loader />;
  }
  if (allLecturesErrors) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>{allLecturesErrors}</p>
      </div>
    );
  }
  return (
    <section className=" width  mb-32 h-full  dark:bg-dark dark:text-light">
      <HelmetTags title="Lectures | Nawar Academy"></HelmetTags>
      <div ref={TitleWrapper} className="title-wrapper  mt-36">
        <h2
          className={`mb-32 text-center text-5xl font-bold transition-all duration-300 ease-linear text-secondary ${
            fade ? "translate-y-14 opacity-0" : "translate-y-0 opacity-100"
          } `}
        >
          {course ? "Courses" : revision ? "Revisions" : "Lectures"}
        </h2>
      </div>
      <div className="lectures grid-auto-fit">
        {data.length === 0 ? (
          <p>No {course ? "Courses" : "Lectures"}</p>
        ) : (
          data.map((Card) => {
            if (Card.is_chapter) {
              return <SingleChapter chapter={Card} key={Card.key} />;
            } else {
              return (
                <SingleCourse
                  lecture={Card}
                  key={Card.key}
                  course={course}
                  revision={Card.is_revision}
                />
              );
            }
          })
        )}
      </div>
    </section>
  );
}

export default StudentLectures;
