import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import SingleCourse from "../../../../MainComponents/SingleCourse";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../../MainComponents/Loader.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags";

function AdminChapter() {
  const { chapterID } = useParams();
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);

  //!---- get chapter info -------
  const [chapterInfoSuccess, chapterInfoErrors, chapterInfoLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_CHAPTER_PAGE_API}${chapterID}`,
    "GET",
    chapterID,
    reFetch || chapterID
  );
  return (
    <section
      className={`  h-auto  w-full  px-20 py-20 md:px-0  dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="Chapters | Nawar Academy"></HelmetTags>
      <div className="mb-20 flex flex-col items-start gap-20">
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_add")
          )) && (
          <Link
            to={`/admin/lectures/${chapterID}/add-lecture`}
            className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add lecture
          </Link>
        )}
      </div>
      {chapterInfoLoading ? (
        <Loader />
      ) : (
        <div className="lectures grid-auto-fit">
          {chapterInfoSuccess?.data?.length === 0 ? (
            <p>There are no lectures for this Chapter</p>
          ) : (
            chapterInfoSuccess?.data?.map((lecture) => (
              <SingleCourse
                reFetch={reFetch}
                setReFetch={setReFetch}
                admin
                lecture={lecture}
                key={lecture.key}
              />
            ))
          )}
        </div>
      )}
    </section>
  );
}

export default AdminChapter;

// function AdminChapter() {
//   const { chapterID } = useParams();
//   const AuthState = useAuthState();
//   const [reFetch, setReFetch] = useState(false);
//   const [lecturesData, setLecturesData] = useState([]);

//   //!---- get chapter info -------
//   const [chapterInfoSuccess, chapterInfoErrors, chapterInfoLoading] = useAxios(
//     `${process.env.REACT_APP_ADMIN_CHAPTER_PAGE_API}${chapterID}`,
//     "GET",
//     chapterID,
//     reFetch || chapterID
//   );

//   useEffect(() => {
//     if (chapterInfoSuccess?.data) {
//       setLecturesData(chapterInfoSuccess.data);
//     }
//   }, [chapterInfoSuccess]);

//   const handleOnDragEnd = (result) => {
//     if (!result.destination) return;

//     const items = Array.from(lecturesData);
//     const [reorderedItem] = items.splice(result.source.index, 1);
//     items.splice(result.destination.index, 0, reorderedItem);

//     setLecturesData(items);

//     // You may want to persist the order change to the backend here
//   };

//   console.log(lecturesData);

//   return (
//     <section
//       className={`h-auto w-full px-20 py-20 md:px-0 dark:bg-dark dark:text-light`}
//     >
//       <HelmetTags title="Chapters | Nawar Academy"></HelmetTags>
//       <div className="mb-20 flex flex-col items-start gap-20">
//         {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
//           AuthState.userData?.admin?.permissions?.some((permission) =>
//             permission.name.startsWith("lecture_add")
//           )) && (
//           <Link
//             to={`/admin/lectures/${chapterID}/add-lecture`}
//             className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90"
//           >
//             <FontAwesomeIcon className="mr-4" icon={faPlus} />
//             Add lecture
//           </Link>
//         )}
//       </div>
//       {chapterInfoLoading ? (
//         <Loader />
//       ) : (
//         <DragDropContext onDragEnd={handleOnDragEnd}>
//           <Droppable droppableId="lectures">
//             {(provided) => (
//               <div
//                 className="lectures grid-auto-fit"
//                 {...provided.droppableProps}
//                 ref={provided.innerRef}
//               >
//                 {lecturesData.length === 0 ? (
//                   <p>There are no lectures for this Chapter</p>
//                 ) : (
//                   lecturesData.map((lecture, index) => (
//                     <Draggable
//                       key={lecture.key}
//                       draggableId={lecture.key.toString()}
//                       index={index}
//                     >
//                       {(provided) => (
//                         <div
//                           ref={provided.innerRef}
//                           {...provided.draggableProps}
//                           {...provided.dragHandleProps}
//                         >
//                           <SingleCourse
//                             reFetch={reFetch}
//                             setReFetch={setReFetch}
//                             admin
//                             lecture={lecture}
//                             key={lecture.key}
//                           />
//                         </div>
//                       )}
//                     </Draggable>
//                   ))
//                 )}
//                 {provided.placeholder}
//               </div>
//             )}
//           </Droppable>
//         </DragDropContext>
//       )}
//     </section>
//   );
// }

// export default AdminChapter;
