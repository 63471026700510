import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import useTable from "../../../../MainComponents/Hooks/useTable";

const ExceptionStudent = ({ permission }) => {
  const { studentID } = useParams();
  const [submitGenerate, setSubmitGenerate] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [refetch, setRefetch] = useState(false);

  //   REACT_APP_GET_STUDENT_LECTURUES_EXCEPTIONS_API;
  const [
    lecturesExceptionInfo,
    lecturesExceptionErrors,
    lecturesExceptionLoading,
  ] = useAxios(
    permission
      ? `${process.env.REACT_APP_GET_STUDENT_LECTURUES_PERMISSION_API}/${studentID}`
      : `${process.env.REACT_APP_GET_STUDENT_LECTURUES_EXCEPTIONS_API}/${studentID}`,
    "GET",
    "GET",
    permission
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });
  let api = permission
    ? process.env.REACT_APP_ADD_STUDENT_LECTURUES_PERMISSION_API
    : process.env.REACT_APP_ADD_STUDENT_LECTURUES_EXCEPTIONS_API;
  const [addEditExceptionSuccess, addEditExceptionErrors, submitLoading] =
    useAxios(
      api,
      "POST",
      submitGenerate.flag,
      submitGenerate.dependency,
      submitGenerate.data,
      true
    );

  const onSubmit = (data) => {
    setSubmitGenerate({
      flag: "addEditExceptionRequest",
      dependency: !submitGenerate.dependency,
      data: { ...data, student_id: studentID },
    });
  };

  const table = useTable(
    permission ? "permissionStudent" : "exceptionStudent",
    "",
    "",
    addEditExceptionSuccess?.data,
    permission,
    studentID,
    ""
  );

  useEffect(() => {
    if (addEditExceptionSuccess) {
      reset();
      setRefetch(!refetch);
    }
  }, [addEditExceptionSuccess, refetch, reset]);

  return (
    <>
      <div className="form-codes flex w-full  items-start justify-center gap-20 lg:flex-col lg:items-center lg:justify-start">
        <div className="flex h-fit  w-1/2 md:w-full flex-col  items-center justify-start gap-6 rounded-3xl   p-10 shadow-lg shadow-red-200/30 ">
          <form
            method="post"
            onSubmit={handleSubmit(onSubmit)}
            className="shadow-l flex h-fit w-full  flex-col items-center justify-start gap-6 rounded-3xl bg-white p-10 shadow-blue/0 md:w-full "
          >
            <div className="second-row flex w-full items-start justify-between gap-6 md:flex-col md:justify-start">
              <div
                className={`flex flex-col w-full items-end justify-center gap-2 md:w-full`}
              >
                <label className="w-full truncate" htmlFor="type">
                  Lectures
                </label>
                <select
                  name="lecture_id"
                  id="lecture_id"
                  defaultValue=""
                  {...register("lecture_id", { required: true })}
                >
                  {lecturesExceptionInfo?.data?.map((lecture) => (
                    <option value={lecture?.key} key={lecture?.key}>
                      {lecture.title}
                    </option>
                  ))}
                </select>

                {errors.lecture_id && (
                  <p className="text-xs text-red-500 ">
                    {errors.session_id?.type === "required" &&
                      "This field is required."}
                  </p>
                )}
                {
                  //!-------server errors -----

                  addEditExceptionErrors &&
                    addEditExceptionErrors?.response?.data?.errors
                      ?.lecture_id && (
                      <p className="w-full text-end text-lg text-red-500  ">
                        {
                          addEditExceptionErrors?.response?.data?.errors
                            ?.lecture_id[0]
                        }
                      </p>
                    )
                }
              </div>
            </div>

            {/** submit */}
            <button
              // disabled={!isValid || (submitLoading && submitGenerate.flag)}
              className=" submit mt-6 w-full "
              type="submit"
            >
              {submitLoading && submitGenerate.flag ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : permission ? (
                "Add Permission"
              ) : (
                "Add Exception"
              )}
            </button>
          </form>
        </div>
      </div>
      <section className="h-full w-full overflow-y-auto px-40 py-20 dark:bg-dark dark:text-light sm:px-10">
        <div className="h-fit  w-full">{table}</div>
      </section>{" "}
    </>
  );
};

export default ExceptionStudent;
