import useTable from "../../../../MainComponents/Hooks/useTable";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useParams } from "react-router-dom";

function OnlineCenterExamsViewTable({ online }) {
  const { examID, groupID } = useParams();

  const table = useTable(
    online ? "viewOnlineExamStudents" : "viewCenterExamStudents",
    "",
    "",
    "",
    "",
    online ? examID : `${groupID}/${examID}`
  );

  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags
        title={`${online ? "Online" : "Center"} Exams | Nawar Academy`}
      ></HelmetTags>

      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default OnlineCenterExamsViewTable;
