//!you may need to change record.id to record.key
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useTable from "../../../../MainComponents/Hooks/useTable";
import toast from "react-hot-toast";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useParams } from "react-router-dom";

function AdminCreateApoiment() {
  const { reservationID } = useParams();
  const [submitGenerate, setSubmitGenerate] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [createAppointmentsSuccess, createAppointmentsErrors, submitLoading] =
    useAxios(
      process.env.REACT_APP_ADMIN_ADD_APPOINTMENTS_API,
      "POST",
      submitGenerate.flag,
      submitGenerate.dependency,
      submitGenerate.data,
      true
    );
  const table = useTable(
    "appointments",
    "",
    "",
    createAppointmentsSuccess,
    "",
    reservationID
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    setSubmitGenerate({
      flag: "createAppointmentsRequest",
      dependency: !submitGenerate.dependency,
      data: {
        ...data,
        reservation_id: reservationID,
      },
    });
  };
  useEffect(() => {
    //! reset the form on success submit

    if (createAppointmentsSuccess) {
      reset();
    }
  }, [createAppointmentsSuccess, reset]);

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title={`Appointments | Nawar Academy`}></HelmetTags>
      <h2 className="w-full text-4xl font-bold md:text-center">Appointments</h2>

      <div className="form-codes-container my-20">
        <h2 className="my-20 text-center text-3xl font-semibold">
          Please fill in the data to add Appointments
        </h2>
        <div className="form-codes flex w-full  items-start justify-center gap-20 lg:flex-col lg:items-center lg:justify-start">
          <form
            method="post"
            onSubmit={handleSubmit(onSubmit)}
            className="flex h-fit  w-1/2 md:w-full flex-col  items-center justify-start gap-6 rounded-3xl   p-10 shadow-lg shadow-red-200/30 md:w-[250px] "
          >
            <div className="first-row flex flex-row-reverse w-full items-center justify-between gap-6 md:flex-col md:justify-start">
              {/** number of codes field */}

              <div
                className={` flex w-full flex-col items-end justify-center gap-2 md:w-full`}
              >
                <label className="w-full truncate text-start" htmlFor="name">
                  Name
                </label>
                <input
                  className="signin-inputs signin-input  w-full "
                  type="text"
                  id="name"
                  placeholder="Name"
                  name="name"
                  autoComplete="on"
                  {...register("name", {
                    required: true,
                  })}
                />

                {errors.name && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors.name.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  createAppointmentsErrors &&
                    createAppointmentsErrors?.response?.data?.errors?.name && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {
                          createAppointmentsErrors?.response?.data?.errors
                            ?.name[0]
                        }
                      </p>
                    )
                }
              </div>
            </div>

            {/** submit */}
            <button
              disabled={!isValid || (submitLoading && submitGenerate.flag)}
              className=" submit mt-6 w-full "
              type="submit"
            >
              {submitLoading && submitGenerate.flag ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "Add Appointment"
              )}
            </button>
          </form>
        </div>
      </div>
      {/**
       * //!---------Table-----
       */}

      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default AdminCreateApoiment;
