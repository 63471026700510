//!you may need to change record.id to record.key
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useTable from "../../../../MainComponents/Hooks/useTable";
import toast from "react-hot-toast";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

function AnalyticsBlocking() {
  const [submitGenerate, setSubmitGenerate] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [getAllBlockingSuccess, getAllBlockingErrors, submitLoading] = useAxios(
    process.env.REACT_APP_ANALYTICS_BLOCKING_STUDENTS_API,
    "POST",
    submitGenerate.flag,
    submitGenerate.dependency,
    submitGenerate.data,
    true
  );
  const [date, setDate] = useState([]);
  const table = useTable(
    "analyticsBlocking",
    "",
    "",
    getAllBlockingSuccess?.data,
    "",
    "",
    true,
    getAllBlockingSuccess?.data
  );
  const [border, setBorder] = useState(false);
  const AuthState = useAuthState();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    setSubmitGenerate({
      flag: "getAllBlockingRequest",
      dependency: !submitGenerate.dependency,
      data: { ...data, start_date: date[0], end_date: date[1] },
    });
  };
  useEffect(() => {
    //! reset the form on success submit

    if (getAllBlockingSuccess) {
      setBorder(true);
      reset();
    }
  }, [getAllBlockingSuccess, reset]);

  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setDate(dateString);
  };
  console.log(date);
  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title={`Blocking | Nawar Academy`}></HelmetTags>
      <h2 className="w-full text-4xl font-bold md:text-center">Blocking</h2>
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("code_add")
        )) && (
        <div className="form-codes-container my-20">
          <div className="form-codes flex w-full  items-start justify-center gap-20 lg:flex-col lg:items-center lg:justify-start">
            <form
              method="post"
              onSubmit={handleSubmit(onSubmit)}
              className="flex h-fit  w-1/2 md:w-full flex-col  items-center justify-start gap-6 rounded-3xl   p-10 shadow-lg shadow-red-200/30 md:w-[250px] "
            >
              <div className=" flex w-full flex-col items-start justify-center gap-2 md:w-full">
                <label
                  className="text-start flex items-center gap-2"
                  htmlFor="stage"
                >
                  Stage
                </label>
                <select
                  className={` signin-inputs signin-input  w-full pl-4  `}
                  name="stage"
                  id="stage"
                  {...register("stage", { required: true })}
                >
                  <option value="1">First secondary</option>
                  <option value="2">Second secondary</option>
                  <option value="3">Third secondary</option>
                </select>
                {errors.stage && (
                  <p className="w-full text-start text-[14px] text-blue-900  ">
                    {errors.stage.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}

                {
                  //!-------Editing server errors -----

                  getAllBlockingErrors?.response?.data?.errors &&
                    getAllBlockingErrors?.response?.data?.errors?.stage && (
                      <p className="w-full text-start text-[14px] text-blue-900  ">
                        {getAllBlockingErrors?.response?.data.errors.stage[0]}
                      </p>
                    )
                }
              </div>
              <div className="first-row flex flex-row-reverse w-full items-center justify-between gap-6 md:flex-col md:justify-start">
                <div
                  className={`w-full flex flex-col items-end justify-center gap-2 md:w-full`}
                >
                  <label className="w-full truncate text-start" htmlFor="count">
                    Date Range
                  </label>

                  <RangePicker
                    className="signin-inputs signin-input  w-full "
                    onChange={onChange}
                  />
                </div>
              </div>

              {/** submit */}
              <button
                disabled={
                  !isValid ||
                  (submitLoading && submitGenerate.flag) ||
                  date[0] === undefined ||
                  date[1] === undefined
                }
                className=" submit mt-6 w-full "
                type="submit"
              >
                {submitLoading && submitGenerate.flag ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "Extract"
                )}
              </button>
            </form>
          </div>
        </div>
      )}
      {/**
       * //!---------Table-----
       */}

      {getAllBlockingSuccess?.data && (
        <div className="h-fit  w-full">{table}</div>
      )}
    </section>
  );
}

export default AnalyticsBlocking;
