import { useParams } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";

const AdminViewExam = () => {
  const { examID } = useParams();
  const table = useTable("viewStudentExam", "", "", "", "", examID);

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
      <HelmetTags title="Exams | Nawar Academy"></HelmetTags>
      <h2 className="mb-20 w-full  text-4xl font-bold md:text-center">
        All Students
      </h2>
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
};

export default AdminViewExam;
