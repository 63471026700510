import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CourseStudentForm = ({
  edit,
  register,
  state,
  errors,
  StudentEditErrors,
  dispatch,
  getValues,
}) => {
  return (
    <>
      {" "}
      <div className=" flex w-full flex-col items-start justify-center  gap-2  md:w-full">
        <label className="text-start flex items-center gap-2" htmlFor="fname">
          Name
        </label>
        <input
          className=" signin-inputs signin-input  w-full pl-4"
          type="text"
          id="fname"
          placeholder="Name"
          name="fname"
          autoComplete="on"
          {...register("fname", {
            required: true,
            pattern: /^[A-Za-z\s]+$/u,
            maxLength: 15,
            minLength: 3,
          })}
        />
        {errors.fname && (
          <p className="w-full text-start text-[12px] text-blue-900  ">
            {errors.fname.type === "required" && "Please fill out this field"}
            {errors.fname.type === "pattern" &&
              "Only English letters are allowed"}
            {errors.fname.type === "maxLength" &&
              "The maximum number of characters is 15 characters"}
            {errors.fname.type === "minLength" &&
              "Please write at least 3 characters"}
          </p>
        )}

        {
          //!-------server errors -----

          state.errors && state.errors.fname && (
            <p className="w-full text-start text-[12px] text-blue-900  ">
              {state.errors.fname[0]}
            </p>
          )
        }
        {
          //!-------Editing server errors -----

          StudentEditErrors?.response?.data?.errors &&
            StudentEditErrors?.response?.data?.errors?.fname && (
              <p className="w-full text-start text-[12px] text-blue-900  ">
                {StudentEditErrors?.response?.data.errors.fname[0]}
              </p>
            )
        }
      </div>
      <div className="w-full md:w-full">
        <div className="  flex w-full flex-col items-start justify-center gap-2">
          <label className="text-start flex items-center gap-2" htmlFor="email">
            Email
          </label>
          <input
            className=" signin-inputs signin-input pl-4 w-full"
            type="email"
            id="email"
            placeholder="Email"
            name="email"
            autoComplete="off"
            {...register("email", {
              required: true,
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            })}
          />
        </div>
        {errors.email && (
          <p className="mt-2 w-full text-start text-[12px] text-blue-900">
            {errors.email.type === "required" && "Please fill out this field"}
            {errors.email.type === "pattern" &&
              "Please enter a valid email address"}
          </p>
        )}

        {
          //!-------server errors -----

          state.errors && state.errors.email && (
            <p className="w-full text-start text-[12px] text-blue-900  ">
              {state.errors.email[0]}
            </p>
          )
        }
        {
          //!-------Editing server errors -----

          StudentEditErrors?.response?.data?.errors &&
            StudentEditErrors?.response?.data?.errors?.email && (
              <p className="w-full text-start text-[12px] text-blue-900  ">
                {StudentEditErrors?.response?.data.errors.email[0]}
              </p>
            )
        }
      </div>
      <div className="w-full">
        <div className="  flex w-full flex-col items-start justify-center gap-2">
          <label
            className="text-start flex items-center gap-2"
            htmlFor="mobile"
          >
            Student number{" "}
          </label>
          <div className="relative w-full">
            <input
              className=" signin-inputs signin-input pl-4   w-full "
              type="number"
              id="mobile"
              placeholder="Student number"
              name="mobile"
              autoComplete="off"
              {...register("mobile", {
                required: true,
                pattern: /^[\d]{11}/,
                maxLength: 11,
              })}
            />
            <FontAwesomeIcon
              className="absolute bottom-1/2 right-4 h-6  w-7 translate-y-1/2"
              icon={faWhatsapp}
            />
          </div>
        </div>
        {errors.mobile && (
          <p className="mt-2 w-full text-start text-[12px] text-blue-900">
            {errors.mobile.type === "required" && "Please fill out this field"}
            {errors.mobile.type === "pattern" &&
              "Please enter an 11-digit phone number"}
            {errors.mobile.type === "maxLength" &&
              "Please enter an 11-digit phone number"}
          </p>
        )}

        {
          //!-------server errors -----

          state.errors && state.errors.mobile && (
            <p className="w-full text-start text-[12px] text-blue-900  ">
              {state.errors.mobile[0]}
            </p>
          )
        }
        {
          //!-------Editing server errors -----

          StudentEditErrors?.response?.data?.errors &&
            StudentEditErrors?.response?.data?.errors?.mobile && (
              <p className="w-full text-start text-[12px] text-blue-900  ">
                {StudentEditErrors?.response?.data.errors.mobile[0]}
              </p>
            )
        }
      </div>
      {!edit && (
        <div className="flex w-full items-start justify-between gap-6 md:flex-col">
          {/** Password  */}
          <div className="w-1/2    md:w-full">
            <div className="relative flex flex-col items-start justify-center gap-2 ">
              <label
                className="text-start flex items-center gap-2"
                htmlFor="password"
              >
                Password
              </label>
              <div
                //!input password relative
                className="relative-hide relative h-auto w-full"
              >
                <input
                  id="password"
                  className=" signin-inputs signin-input pl-4   w-full"
                  type={`${state.hide ? "text" : "password"}`}
                  placeholder="Password"
                  name="password"
                  autoComplete="on"
                  {...register("password", {
                    required: true,
                    maxLength: 25,
                    minLength: 6,
                  })}
                />

                <div
                  //!eye icons
                  onClick={() =>
                    dispatch({
                      type: "setHide",
                    })
                  }
                  className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                >
                  {state.hide ? (
                    <FontAwesomeIcon className="h-full w-full" icon={faEye} />
                  ) : (
                    <FontAwesomeIcon
                      className="h-full w-full"
                      icon={faEyeSlash}
                    />
                  )}
                </div>
              </div>
            </div>
            {errors.password && (
              <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                {errors.password.type === "required" &&
                  "Please fill out this field"}
                {errors.password.type === "maxLength" &&
                  "The maximum number of characters is 25 characters"}
                {errors.password.type === "minLength" &&
                  "The minimum number of characters is 6 characters"}
              </p>
            )}

            {
              //!-------server errors -----

              state.errors && state.errors.password && (
                <p className="w-full text-start text-[12px] text-blue-900  ">
                  {state.errors.password[0]}
                </p>
              )
            }
          </div>

          {/** Confirm Password  */}
          <div className="w-1/2   md:w-full">
            <div className="relative flex flex-col items-start justify-center gap-2   ">
              <label
                className="text-start flex items-center gap-2"
                htmlFor="password_confirmation"
              >
                Confirm the password
              </label>

              <div
                //!input password relative
                className="relative-hide relative h-auto w-full"
              >
                <input
                  id="password_confirmation"
                  className=" signin-inputs signin-input pl-4   w-full"
                  type={`${state.hide ? "text" : "password"}`}
                  placeholder="Confirm the password"
                  name="password_confirmation"
                  autoComplete="on"
                  {...register("password_confirmation", {
                    required: true,
                    maxLength: 25,
                    minLength: 6,
                    validate: (value) => value === getValues("password"),
                  })}
                />

                <div
                  //!eye icons
                  onClick={() =>
                    dispatch({
                      type: "setHide",
                    })
                  }
                  className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                >
                  {state.hide ? (
                    <FontAwesomeIcon className="h-full w-full" icon={faEye} />
                  ) : (
                    <FontAwesomeIcon
                      className="h-full w-full"
                      icon={faEyeSlash}
                    />
                  )}
                </div>
              </div>
            </div>

            {errors.password_confirmation && (
              <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                {errors.password_confirmation.type === "required" &&
                  "Please fill out this field"}
                {errors.password_confirmation.type === "maxLength" &&
                  "The maximum number of characters is 25 characters"}
                {errors.password_confirmation.type === "minLength" &&
                  "The minimum number of characters is 6 characters"}
                {errors.password_confirmation.type === "validate" &&
                  "Passwords do not match"}
              </p>
            )}

            {
              //!-------server errors -----

              state.errors && state.errors.password_confirmation && (
                <p className="w-full text-start text-[12px] text-blue-900  ">
                  {state.errors.password_confirmation[0]}
                </p>
              )
            }
          </div>
        </div>
      )}
      {!edit && (
        <div className="flex w-full flex-col items-start gap-2">
          <label className="w-full  " htmlFor="profile_pic">
            Profile Picture (الصورة الشخصية)
          </label>

          <input
            id="profile_pic"
            className=" signin-inputs signin-input w-full !pt-[12px]"
            type="file"
            accept="image/jpg, image/jpeg, image/png, image/webp"
            name="profile_pic"
            {...register("profile_pic", {
              required: true,
              validate: (value) => !(value[0]?.size > 5000000),
            })}
          />

          {errors.profile_pic && (
            <p className="mt-2 text-xs text-blue-500">
              {errors.profile_pic.type === "required" && "Add profile picture"}
              {errors.profile_pic.type === "validate" && "Maximum size is 5 MB"}
            </p>
          )}
          {
            //!-------server errors -----

            StudentEditErrors &&
              StudentEditErrors?.response?.data?.errors?.profile_pic && (
                <p className="text-xs text-blue-500  ">
                  {StudentEditErrors?.response?.data?.errors?.profile_pic[0]}
                </p>
              )
          }
        </div>
      )}
    </>
  );
};

export default CourseStudentForm;
