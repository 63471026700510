import useTable from "../../../../MainComponents/Hooks/useTable";
import HelmetTags from "../../../../MainComponents/HelmetTags";

function AdminStudentsCourses() {
  const table = useTable("students-courses");

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Students Courses | Nawar Academy"></HelmetTags>
      <div className="mb-20 flex flex-col items-start gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">
          Students Courses
        </h2>
      </div>
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default AdminStudentsCourses;
