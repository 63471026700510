import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import QuizTimer from "./QuizTimer";

const QuizHeader = ({
  state,
  dispatch,
  quizErrors,
  onFinishQuiz,
  quizLoading,
  exam,
}) => {
  const { ID } = useParams();

  return (
    <header
      className={`w-full sticky top-0 ${
        state.isShowAnswers ? "h-[80px]" : "h-[128px]"
      }  flex items-center bg-secondary text-light shadow-md shadow-secondary/40`}
    >
      <nav
        className={`nav-container width flex h-fit items-center gap-[20px] ${
          state.isShowAnswers ? "flex-row justify-between" : "flex-col"
        }`}
      >
        <div className="header-above  h-[32px] w-fit">
          <h4 className="exam-title font-bold">{state?.quizInfo?.title}</h4>
        </div>

        {state.isShowAnswers && (
          <Link to={`${exam ? `/exams/${ID}` : `/lectures/sessions/${ID}`}`}>
            <div className="collapse-btn top-[96px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-accent text-light shadow-md shadow-black/40 duration-300 hover:scale-110 hover:text-light">
              <FontAwesomeIcon
                className={`transition-all duration-300 ease-in-out hover:scale-110 ${
                  state.collapse && "rotate-180"
                }`}
                icon={faChevronRight}
              />
            </div>
          </Link>
        )}
        <div
          className={`header-underneath  flex w-full items-center justify-center gap-[20px] ${
            state.isShowAnswers && "pointer-events-none hidden"
          }`}
        >
          {state.isShowAnswers ||
            (state.quizInfo?.duration && (
              <QuizTimer
                duration={state.quizInfo.duration}
                state={state}
                onFinishQuiz={onFinishQuiz}
              />
            ))}
        </div>
      </nav>
    </header>
  );
};

export default QuizHeader;
