import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useParams } from "react-router-dom";
import { Loader } from "../../../../MainComponents";
import { useState } from "react";

function OnlineStudentsGroup({ attendance }) {
  const { stage, groupId } = useParams();
  const [groupNum, setGroupNum] = useState(0);

  const [allGroups, allGroupsErrors, allGroupsLoading] = useAxios(
    `${process.env.REACT_APP_GETL_STUDENT_ONLINE_BY_STAGE_API}/${stage}`,
    "GET",
    "GET",
    ""
  );

  // const [allAttendanceGroups, allGroupsErrors, allGroupsLoading] = useAxios(
  //   `${process.env.REACT_APP_GETL_STUDENT_ONLINE_BY_STAGE_API}/${stage}`,
  //   "GET",
  //   "GET",
  //   ""
  // );

  const table = useTable(
    attendance ? "" : "studentsOnlineInGroup",
    "",
    "",
    groupNum,
    "",
    "",
    true,
    allGroups?.data?.students.filter((data) => +data.group_n === +groupNum)
  );

  const attendanceTable = useTable(
    "onlineAttendanceGroup",
    "",
    "",
    groupNum,
    "",
    `${stage}/${groupNum}`
  );

  function createArray(N) {
    let newArr = [];
    for (let i = 1; i <= N; i++) {
      newArr.push(i);
    }
    return newArr;
  }

  const groups = createArray(allGroups?.data?.group_number);

  if (allGroupsLoading) {
    return <Loader />;
  }
  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title={`Online Students | Nawar Academy`}></HelmetTags>

      <div className="top-btns flex w-full justify-start flex-wrap gap-10 asm:flex-col asm:items-center asm:justify-start asm:gap-14">
        {groups?.map((group, index) => (
          <button
            key={index}
            onClick={() => {
              setGroupNum(group);
            }}
            className={`md:w-full rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary  active:scale-90 ${
              groupNum !== group
                ? "bg-secondary text-white"
                : "bg-white text-secondary"
            }`}
          >
            Group {group}
          </button>
        ))}
      </div>

      {groupNum ? (
        <div className="h-fit  w-full">
          {attendance ? attendanceTable : table}
        </div>
      ) : null}
    </section>
  );
}

export default OnlineStudentsGroup;
