//!you may need to change record.id to record.key
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useTable from "../../../../MainComponents/Hooks/useTable";
import toast from "react-hot-toast";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { CSVLink } from "react-csv";

function AdminDiscountStudents({ activation, vodafone }) {
  const [submitGenerate, setSubmitGenerate] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [generateDiscountSuccess, generateDiscountErrors, submitLoading] =
    useAxios(
      process.env.REACT_APP_EDIT_DISCOUNT_STUDENTS_API,
      "POST",
      submitGenerate.flag,
      submitGenerate.dependency,
      submitGenerate.data,
      true
    );
  const table = useTable("discount-students", "", "", generateDiscountSuccess);
  const [border, setBorder] = useState(false);
  const AuthState = useAuthState();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    setSubmitGenerate({
      flag: "generateDiscountRequest",
      dependency: !submitGenerate.dependency,
      data,
    });
  };
  useEffect(() => {
    if (generateDiscountSuccess) {
      reset();
    }
  }, [generateDiscountSuccess, reset]);

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title={`Discount's Students | Nawar Academy`}></HelmetTags>
      <h2 className="w-full text-4xl font-bold md:text-center">
        Discount's Students
      </h2>
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("code_add")
        )) && (
        <div className="form-codes-container my-20">
          <h2 className="my-20 text-center text-3xl font-semibold">
            Please fill in the data to Make the discount
            {activation
              ? " activation codes"
              : vodafone
                ? " Vodafone Cash Codes"
                : " Codes"}
          </h2>
          <div className="form-codes flex w-full  items-start justify-center gap-20 lg:flex-col lg:items-center lg:justify-start">
            <form
              method="post"
              onSubmit={handleSubmit(onSubmit)}
              className="flex h-fit  w-1/2 md:w-full  flex-col  items-center justify-start gap-6 rounded-3xl   p-10 shadow-lg shadow-red-200/30 "
            >
              <div className="w-full">
                <div className="  flex w-full flex-col items-start justify-center gap-2">
                  <label
                    className="text-start flex items-center gap-2"
                    htmlFor="mobile"
                  >
                    Student number{" "}
                  </label>
                  <div className="relative w-full">
                    <input
                      className=" signin-inputs signin-input pl-4   w-full "
                      type="number"
                      id="mobile"
                      placeholder="Student number"
                      name="mobile"
                      autoComplete="off"
                      {...register("mobile", {
                        required: true,
                        pattern: /^[\d]{11}/,
                        maxLength: 11,
                      })}
                    />
                  </div>
                </div>
                {errors.mobile && (
                  <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                    {errors.mobile.type === "required" &&
                      "Please fill out this field"}
                    {errors.mobile.type === "pattern" &&
                      "Please enter an 11-digit phone number"}
                    {errors.mobile.type === "maxLength" &&
                      "Please enter an 11-digit phone number"}
                  </p>
                )}

                {
                  //!-------Editing server errors -----

                  generateDiscountErrors?.response?.data?.errors &&
                    generateDiscountErrors?.response?.data?.errors?.mobile && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {
                          generateDiscountErrors?.response?.data.errors
                            .mobile[0]
                        }
                      </p>
                    )
                }
              </div>
              <div className="w-full">
                <div className="  flex w-full flex-col items-start justify-center gap-2">
                  <label
                    className="text-start flex items-center gap-2"
                    htmlFor="discount"
                  >
                    Discount
                  </label>
                  <div className="relative w-full">
                    <input
                      className=" signin-inputs signin-input pl-4   w-full "
                      type="number"
                      id="discount"
                      placeholder="Discount"
                      name="discount"
                      autoComplete="off"
                      {...register("discount", {
                        required: true,
                      })}
                    />
                  </div>
                </div>
                {errors.discount && (
                  <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                    {errors.discount.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}

                {
                  //!-------Editing server errors -----

                  generateDiscountErrors?.response?.data?.errors &&
                    generateDiscountErrors?.response?.data?.errors
                      ?.discount && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {
                          generateDiscountErrors?.response?.data.errors
                            .discount[0]
                        }
                      </p>
                    )
                }
              </div>
              {/** submit */}
              <button
                disabled={!isValid || (submitLoading && submitGenerate.flag)}
                className=" submit mt-6 w-full "
                type="submit"
              >
                {submitLoading && submitGenerate.flag ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      )}
      {/**
       * //!---------Table-----
       */}

      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default AdminDiscountStudents;
