import StudentChangePassword from "./components/StudentChangePassword";
import StudentChangeImage from "./components/StudentChangeImage";
import StudentActivationAccount from "./components/StudentActivationAccount";
import { useAuthState } from "../../../MainComponents/GlobalContext";

const Profile = () => {
  const AuthState = useAuthState();

  return (
    <section className="h-auto width flex flex-col items-center">
      {AuthState.userData.student.active === 0 && <StudentActivationAccount />}

      <StudentChangeImage />
      <StudentChangePassword />
    </section>
  );
};

export default Profile;
