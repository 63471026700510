import { Popconfirm } from "antd";
import { BsThreeDots } from "react-icons/bs";
import { useAuthState } from "../../../../../../MainComponents/GlobalContext";

const Message = ({ msg, handleDeleteMessage }) => {
  const AuthState = useAuthState();

  return (
    <div
      className={`flex  h-auto min-h-fit ${
        msg?.to_teacher === 1 ? "justify-start" : "justify-end"
      } `}
    >
      <div className={`w-3/4 h-full min-h-[56px] `}>
        <h5
          className={` mb-1 font-bold ${
            msg?.to_teacher === 1 ? "text-left" : "text-right"
          }`}
        >
          {msg?.to_teacher ? msg?.student_name : msg?.teacher_name}
        </h5>
        <div
          className={`flex justify-between gap-4 w-full mb-1 ${
            msg?.to_teacher === 1 ? "flex-row-reverse" : ""
          }`}
        >
          <div className={`w-full flex items-center gap-2`}>
            {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              (AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("ask")
              ) && msg?.to_teacher !== 1 ? (
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#ffffff"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={() => handleDeleteMessage(msg.key)}
                  title="Are you sure you want to delete?"
                >
                  <button className="w-fit h-fit text-center font-semibold text-bg duration-300  text-black ">
                    <BsThreeDots />
                  </button>
                </Popconfirm>
              ) : null)}

            <div
              className={`rounded-2xl p-3 w-full ${
                msg?.to_teacher === 1
                  ? "bg-green  text-secondary"
                  : "bg-secondary  text-white"
              }`}
            >
              {msg?.type === "text" && (
                <p className=" break-all" style={{ wordBreak: "break-word" }}>
                  {msg.msg}
                </p>
              )}
              {msg?.type === "img" && (
                <img
                  className="max-w-[160px] h-auto"
                  src={msg?.src}
                  alt="msg"
                />
              )}

              {msg?.type === "voice" && (
                <audio
                  className="h-9 w-full "
                  type="video/webm"
                  controls
                  src={msg?.src}
                />
              )}
            </div>
          </div>

          <img
            className="h-9 aspect-square border-2 border-bg overflow-hidden rounded-full object-cover min-h-[36px] min-w-[36px]"
            src={msg?.to_teacher === 1 ? msg?.student_img : msg?.teacher_img}
            alt=""
          />
        </div>
        <span
          className={`flex  ${
            msg?.to_teacher === 1 ? "justify-end" : "justify-start"
          }`}
        >
          {msg?.date}
        </span>
      </div>
    </div>
  );
};

export default Message;
