import { useEffect, useState } from "react";
import SingleCourse from "../../../../MainComponents/SingleCourse";
import SingleChapter from "../../../../MainComponents/SingleChapter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { Loader } from "../../../../MainComponents";

function AdminCourses() {
  const navigate = useNavigate();
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);
  const [stage, setStage] = useState(null);
  const [allLecturesData, allLecturesErrors, allLecturesloading] = useAxios(
    process.env.REACT_APP_ADMIN_LECTURES_TAB_API,
    "GET",
    "GET",
    reFetch
  );

  return (
    <section
      className={`h-full  w-full  px-20 py-20 dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="Courses | Nawar Academy"></HelmetTags>
      <div className="mb-20 flex flex-col items-end gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">Courses</h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_add")
          )) && (
          <div className="flex w-full gap-10 md:justify-evenly">
            <button
              onClick={() => navigate("/admin/courses/add-course")}
              className="mr-4 rounded-xl border-2 border-secondary order-2 bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add Course
            </button>
          </div>
        )}
      </div>

      {allLecturesloading ? (
        <Loader />
      ) : (
        <div className="lectures grid-auto-fit mb-[50px]">
          {allLecturesData?.data?.length === 0 ? (
            <p>No lectures</p>
          ) : (
            allLecturesData?.data
              ?.filter((lect) => +lect.is_course === 1)
              .map((Card) => (
                <SingleCourse
                  reFetch={reFetch}
                  setReFetch={setReFetch}
                  admin
                  lecture={Card}
                  key={Card.key}
                  course
                />
              ))
          )}
        </div>
      )}
    </section>
  );
}

export default AdminCourses;
