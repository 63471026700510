import { useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { Loader } from "../../../../MainComponents";
import { DatePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";

function LateStudents() {
  const [reFetch, setReFetch] = useState(false);
  const [date, setDate] = useState("");
  const [stage, setStage] = useState("");
  const [lecture, setLecture] = useState("");

  const [submitGenerate, setSubmitGenerate] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [
    getAllLatedLectures,
    getAllLatedLecturesErrors,
    getAllLatedLecturesLoading,
  ] = useAxios(
    `${process.env.REACT_APP_ADMIN_GET_ALL_LECTURE_STUDENT_API}`,
    "GET",
    "GET",
    reFetch
  );

  const [getAllLeatedSuccess, getAllLeatedErrors, getAllLeatedLoading] =
    useAxios(
      process.env.REACT_APP_ADMIN_GET_ALL_LATE_STUDENTS_API,
      "POST",
      submitGenerate.flag,
      submitGenerate.dependency,
      submitGenerate.data,
      true
    );

  const onSubmit = () => {
    if (stage === "" || lecture === "") {
      toast.error("Please Select Values");
      return;
    }
    const data = {
      date: date,
      stage: stage,
      id: lecture,
    };

    setSubmitGenerate({
      flag: "getLatedStudents",
      dependency: !submitGenerate.dependency,
      data: data,
    });
  };

  const table = useTable(
    "latedStudents",
    false,
    "",
    getAllLeatedSuccess?.data,
    reFetch,
    "",
    true,
    getAllLeatedSuccess?.data,
    reFetch,
    setReFetch
  );

  //   const onChange = (_, dateString) => {
  //     setDate(dateString);
  //   };

  const filteredLectures = getAllLatedLectures?.data?.filter(
    (lecture) => +lecture.stage === +stage
  );
  if (getAllLatedLecturesLoading) return <Loader />;

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title="Late Students | Nawar Academy"></HelmetTags>{" "}
      <h2 className="w-full text-4xl font-bold md:text-center mb-12">
        Late Students
        {getAllLeatedSuccess?.data?.length > 0 &&
          `: ${getAllLeatedSuccess?.data?.length}`}
      </h2>
      <div className="flex h-fit  w-1/2 mx-auto my-12 flex-col  items-center justify-start gap-6 rounded-3xl   p-10 shadow-lg shadow-red-200/30 md:w-[250px] ">
        {/* <div className="w-full flex flex-col gap-3">
          <label htmlFor="date">Date</label>
          <DatePicker
            id="date"
            className="signin-inputs signin-input"
            onChange={onChange}
          />
        </div> */}
        <div className="w-full flex flex-col gap-3">
          <label htmlFor="stage">Stage</label>
          <select
            name="stage"
            id="stage"
            value={stage}
            onChange={(e) => setStage(e.target.value)}
          >
            <option value="" selected disabled hidden>
              Choose Stage
            </option>

            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div>

        <div className="w-full flex flex-col gap-3">
          <label htmlFor="lecture">Lecture</label>
          <select
            name="lecture"
            id="lecture"
            value={lecture}
            onChange={(e) => setLecture(e.target.value)}
          >
            <option value="" selected disabled hidden>
              Choose Lecture
            </option>
            {filteredLectures?.map((lecture, index) => (
              <option value={lecture?.key} key={index}>
                {lecture?.title}
              </option>
            ))}
          </select>
        </div>
        <button
          disabled={getAllLeatedLoading}
          className=" submit mt-6 w-full "
          type="submit"
          onClick={onSubmit}
        >
          {getAllLeatedLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            "Late Students"
          )}
        </button>
      </div>
      {getAllLeatedSuccess?.data?.length > 0 && (
        <div className="h-fit  w-full">{table}</div>
      )}
    </section>
  );
}

export default LateStudents;
