//!visibility is available in edit only not in add
import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor as ClassicEditor } from "ckeditor5-custom-build/build/ckeditor";

function AddEditCourse({ edit }) {
  const [values, setValues] = useState(null);
  const [content, setContent] = useState("");

  const { courseID, chapterID } = useParams();
  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditCourse": {
        return {
          ...state,
          submitAddEdit: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEdit: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_LECTURE_API
    : process.env.REACT_APP_ADMIN_ADD_LECTURE_API;
  //!--------- add edit Lecture -------

  const [CourseAddEditSuccess, CourseAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEdit.flag,
    state.submitAddEdit.dependency,
    state.submitAddEdit.data,
    true
  );

  //!--------- get the Lecture info for editing -------

  const [CourseInfo, CourseInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_LECTURE_INFO_API}${courseID}`,
    "GET",
    courseID,
    courseID
  );

  const [LecturesData, LectureDataErrors] = useAxios(
    process.env.REACT_APP_ADMIN_GET_ALL_LECTURES_API,
    "GET",
    "GET",
    true
  );

  useEffect(() => {
    if (CourseInfo) {
      let temp = CourseInfo.data;
      delete temp.img;
      setContent(temp.description || "");
      setValues({
        ...temp,
        // next_lecture: temp.next_lecture,
        visibility: temp.visibility === 1 ? true : false,
        enable_buy: temp.enable_buy === 1 ? true : false,
      });
    }
  }, [CourseInfo]);

  useEffect(() => {
    //!---add actions ----

    if (CourseAddEditSuccess) {
      navigate(-1);
      reset();
    }
    //! make the dependency false again to listen to the next try to submit
  }, [CourseAddEditSuccess]);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  const onSubmit = (data) => {
    const finalData = courseID
      ? {
          ...data,
          id: courseID,
          img: data?.img[0] || null,
          description: content,
          visibility: data?.visibility === true ? 1 : 0,
          is_course: 1,
        }
      : {
          ...data,
          description: content,
          img: data?.img[0] || null,
          visibility: data?.visibility === true ? 1 : 0,
          is_course: 1,
        };
    dispatch({
      type: "setSubmitAddEditCourse",
      payload: {
        flag: "AddEditLecture",
        dependency: !state.submitAddEdit.dependency,
        data: finalData,
      },
    });
  };

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Courses | Nawar Academy"></HelmetTags>
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          {courseID
            ? "Edit lecture data"
            : "Please fill in the information to add the lecture."}
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl   p-16 shadow-lg shadow-blue-200/50"
        >
          {/** Name stage filed */}
          <div className="flex w-full items-start justify-between gap-10 md:flex-col ">
            <div className="name flex w-full flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="title">Name</label>
              <input
                className="signin-inputs signin-input w-full pl-4    "
                type="text"
                id="title"
                placeholder="Name"
                name="title"
                autoComplete="on"
                {...register("title", {
                  required: true,
                  //pattern: /^[A-Za-z]+$/,
                  maxLength: 40,
                  minLength: 3,
                })}
              />
              {errors.title && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {errors.title.type === "required" &&
                    "Please fill out this field"}
                  {errors.title.type === "pattern" &&
                    "Only English letters are allowed"}
                  {errors.title.type === "maxLength" &&
                    "The maximum number of characters is 40 characters"}
                  {errors.title.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}

              {
                //!-------server errors -----

                CourseAddEditErrors &&
                  CourseAddEditErrors?.response?.data?.errors?.title && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {CourseAddEditErrors?.response?.data?.errors?.title[0]}
                    </p>
                  )
              }
            </div>
          </div>

          <div className="flex w-full flex-col items-end gap-2">
            <label className="w-full" htmlFor="img">
              Upload the lecture image
            </label>

            <input
              id="img"
              className="signin-inputs signin-input   w-full"
              type="file"
              accept="image/jpg, image/jpeg, image/png, image/webp"
              name="img"
              {...register("img", {
                required: values ? false : true,
                validate: (value) => !(value[0]?.size > 5000000),
              })}
            />

            {errors.img && (
              <p className="mt-2 w-full  text-[12px] text-blue-900">
                {errors.img.type === "required" &&
                  "Please add a picture of the lecture"}
                {errors.img.type === "validate" && "Maximum image size is 5MB"}
              </p>
            )}
            {
              //!-------server errors -----

              CourseAddEditErrors &&
                CourseAddEditErrors?.response?.data?.errors?.img && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {CourseAddEditErrors?.response?.data?.errors?.img[0]}
                  </p>
                )
            }
          </div>

          <div
            className={`name flex w-full flex-col items-start justify-center  gap-2  md:w-full`}
          >
            <label htmlFor="price">Price</label>
            <input
              className="signin-inputs signin-input w-full pl-4    "
              type="number"
              id="price"
              placeholder="Price"
              name="price"
              autoComplete="on"
              {...register("price", {
                required: true,
              })}
            />
            {errors.price && (
              <p className="w-full  text-[12px] text-blue-900  ">
                {errors.price.type === "required" &&
                  "Please fill out this field"}
              </p>
            )}

            {
              //!-------server errors -----

              CourseAddEditErrors &&
                CourseAddEditErrors?.response?.data?.errors?.price && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {CourseAddEditErrors?.response?.data?.errors?.price[0]}
                  </p>
                )
            }
          </div>
          <div className=" days flex w-full flex-col items-end justify-center gap-2 md:w-full">
            <label className="w-full truncate" htmlFor="expired_at">
              Number of days
            </label>
            <input
              className="signin-inputs signin-input w-full "
              type="number"
              min={1}
              id="expired_at"
              placeholder="000"
              name="expired_at"
              autoComplete="on"
              {...register("expired_at", {
                required: true,
                valueAsNumber: true,
              })}
            />

            {errors.expired_at && (
              <p className="text-[12px] text-blue-900 ">
                {errors.expired_at.type === "required" &&
                  "Please fill out this field"}
              </p>
            )}
            {
              //!-------server errors -----

              CourseAddEditErrors &&
                CourseAddEditErrors?.response?.data?.errors?.expired_at && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {CourseAddEditErrors?.response?.data?.errors?.expired_at[0]}
                  </p>
                )
            }
          </div>
          {edit && (
            <div className="visibility flex w-full flex-col items-end justify-center gap-2 md:w-full">
              <div className="flex w-full items-center justify-end gap-10">
                <label className="visibility-switch">
                  <input
                    className=""
                    id="visibility"
                    name="visibility"
                    {...register("visibility", {
                      required: false,
                    })}
                    type="checkbox"
                  />
                  <span className="visibility-slider">
                    <svg
                      className="slider-icon"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="m4 16.5 8 8 16-16"></path>
                    </svg>
                  </span>
                </label>
                <div className="order-1">
                  <label
                    className="w-full cursor-pointer truncate"
                    htmlFor="visibility"
                  >
                    Visibility
                  </label>
                </div>
              </div>

              {errors.visibility && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.visibility.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                CourseAddEditErrors &&
                  CourseAddEditErrors?.response?.data?.errors?.visibility && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {
                        CourseAddEditErrors?.response?.data?.errors
                          ?.visibility[0]
                      }
                    </p>
                  )
              }
            </div>
          )}

          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate " htmlFor="description">
              Description
            </label>
            <CKEditor
              config={{
                toolbar: {
                  items: [
                    "undo",
                    "redo",
                    "|",
                    "imageInsert",
                    "|",
                    "heading",
                    "bold",
                    "italic",
                    "strikethrough",
                    "underline",
                    "link",
                    "blockQuote",
                    "removeFormat",
                    "|",
                    "selectAll",
                    "specialCharacters",
                    "superscript",
                    "subscript",
                    "style",
                    "|",
                    "bulletedList",
                    "numberedList",
                    "outdent",
                    "indent",
                    "|",
                    "mediaEmbed",
                    "insertTable",
                    "|",
                    "fontFamily",
                    "fontColor",
                    "fontBackgroundColor",
                    "fontSize",
                    "highlight",
                    "|",
                    "horizontalLine",
                    "pageBreak",
                    "findAndReplace",
                    "restrictedEditingException",
                    "textPartLanguage",
                    "-",
                  ],
                  shouldNotGroupWhenFull: true,
                },
                language: "en",
                image: {
                  toolbar: [
                    "imageTextAlternative",
                    "toggleImageCaption",
                    "imageStyle:inline",
                    "imageStyle:block",
                    "imageStyle:side",
                  ],
                },
                table: {
                  contentToolbar: [
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells",
                    "tableCellProperties",
                    "tableProperties",
                  ],
                },
              }}
              editor={ClassicEditor}
              data={content}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContent(data);
              }}
            />
            {errors.description && (
              <p className="text-[12px] text-blue-900 ">
                {errors.description.type === "maxLength" &&
                  "The maximum number of characters is 155 characters"}
                {errors.description.type === "minLength" &&
                  "Please write at least 3 characters"}
              </p>
            )}
            {
              //!-------server errors -----

              CourseAddEditErrors &&
                CourseAddEditErrors?.response?.data?.errors?.description && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {
                      CourseAddEditErrors?.response?.data?.errors
                        ?.description[0]
                    }
                  </p>
                )
            }
          </div>
          {/** submit */}

          <button
            disabled={!isValid || (submitLoading && state.submitAddEdit.flag)}
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEdit.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>{courseID ? "Edit Course" : "Add Course"}</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditCourse;
