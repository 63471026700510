import { useRef, useEffect } from "react";
import Message from "./Message";

const ChatContent = ({ messages, handleDeleteMessage }) => {
  const chatContentRef = useRef(null);

  useEffect(() => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div
      className="chatContent w-full p-4  flex flex-col !gap-5 px-3"
      ref={chatContentRef}
    >
      {messages?.map((msg, i) => (
        <Message msg={msg} key={i} handleDeleteMessage={handleDeleteMessage} />
      ))}
    </div>
  );
};

export default ChatContent;
