//!------edit in table ------

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function AddEditGroup({ edit }) {
  const navigate = useNavigate();
  const [values, setValues] = useState(null);
  const { groupID } = useParams();

  const [submitGroup, setSubmitGroup] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const addEditApi = edit
    ? process.env.REACT_APP_EDIT_GROUP_API
    : process.env.REACT_APP_ADD_GROUPS_API;
  const [addGroupSuccess, addGroupErrors, submitLoading] = useAxios(
    addEditApi,
    "POST",
    submitGroup.flag,
    submitGroup.dependency,
    submitGroup.data,
    true
  );
  const [getGroupSuccess, getGroupErrors, getGroupLoading] = useAxios(
    `${process.env.REACT_APP_ALL_GROUPS_API}/${groupID}`,
    "GET",
    edit,
    edit
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm({ mode: "onChange", values });

  const watchPlace = watch("is_online");

  const onSubmit = (data) => {
    setSubmitGroup({
      flag: "addGroupRequest",
      dependency: !submitGroup.dependency,
      data: { ...data, id: groupID, description: data?.description || null },
    });
  };

  useEffect(() => {
    if (getGroupSuccess) {
      setValues(getGroupSuccess.data);
    }
  }, [getGroupSuccess]);

  useEffect(() => {
    //! reset the form on success submit

    if (addGroupSuccess) {
      navigate(-1);
      reset();
    }
  }, [addGroupSuccess]);
  return (
    <section className="flex h-full w-full flex-col items-center dark:bg-dark dark:text-light">
      <HelmetTags title="Groups | Nawar Academy"></HelmetTags>
      <div className="form-container my-20 flex flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          Please fill out the information to add the group
        </h2>

        <form
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-fit w-[380px] flex-col  items-center justify-start gap-6 rounded-3xl   p-10 shadow-lg shadow-red-200/30 md:w-[250px] "
        >
          {/** group name */}

          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate" htmlFor="name">
              Group name
            </label>
            <input
              className="signin-inputs signin-input  w-full "
              type="text"
              id="name"
              placeholder="Enter group name"
              name="name"
              autoComplete="on"
              {...register("name", {
                required: true,

                maxLength: 100,
                minLength: 3,
              })}
            />
            {errors.name && (
              <p className="text-[12px] text-blue-900 ">
                {errors.name.type === "required" &&
                  "Please fill out this field"}

                {errors.name.type === "maxLength" &&
                  "The maximum number of characters is 100 characters."}
                {errors.name.type === "minLength" &&
                  "Please write at least 3 characters"}
              </p>
            )}
            {
              //!-------server errors -----

              addGroupErrors &&
                addGroupErrors?.response?.data?.errors?.name && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {addGroupErrors?.response?.data?.errors?.name[0]}
                  </p>
                )
            }
          </div>

          {/** chapter description */}

          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate " htmlFor="description">
              Description
            </label>
            <textarea
              className=" resize-none"
              {...register("description", {
                maxLength: 155,
                minLength: 3,
              })}
              name="description"
              id="description"
              cols="40"
              rows="5"
            ></textarea>
            {errors.description && (
              <p className="text-[12px] text-blue-900 ">
                {errors.description.type === "maxLength" &&
                  "The maximum number of characters is 155 characters"}
                {errors.description.type === "minLength" &&
                  "Please write at least 3 characters"}
              </p>
            )}
          </div>

          <div
            className={`flex w-full flex-col items-end justify-center  gap-2 `}
          >
            <label className="w-full truncate" htmlFor="is_online">
              Place
            </label>
            <select
              name="is_online"
              id="is_online"
              {...register("is_online", { required: true })}
            >
              <option value="0">Center</option>
              <option value="1">Online</option>
            </select>

            {errors.is_online && (
              <p className="text-[12px] text-blue-900 ">
                {errors.is_online.type === "required" &&
                  "Please fill out this field"}
              </p>
            )}
            {
              //!-------server errors -----

              addGroupErrors &&
                addGroupErrors?.response?.data?.errors?.is_online && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {addGroupErrors?.response?.data?.errors?.is_online[0]}
                  </p>
                )
            }
          </div>

          {/** Stage*/}
          {watchPlace === "0" && (
            <div
              className={`flex w-full flex-col items-end justify-center  gap-2 `}
            >
              <label className="w-full truncate" htmlFor="stage">
                Class
              </label>
              <select
                name="stage"
                id="stage"
                {...register("stage", { required: true })}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>

              {errors.stage && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.stage.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                addGroupErrors &&
                  addGroupErrors?.response?.data?.errors?.stage && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {addGroupErrors?.response?.data?.errors?.stage[0]}
                    </p>
                  )
              }
            </div>
          )}

          {/** submit */}

          <button
            disabled={!isValid || (submitLoading && submitGroup.flag)}
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && submitGroup.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <>{edit ? "Edit " : "Add "} Group</>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditGroup;
