const Message = ({ msg }) => {
  return (
    <div className={`w-full flex justify-end h-auto min-h-fit `}>
      <div className={`w-full h-full min-h-[56px] `}>
        <h5
          className={` mb-1 font-bold ${
            msg.to_teacher === 1 ? "text-right" : "text-left"
          }`}
        >
          {msg.to_teacher ? msg.student_name : msg.teacher_name}
        </h5>
        <div
          className={`flex justify-between gap-4 w-full mb-1 ${
            msg.to_teacher === 1 ? "" : "flex-row-reverse"
          }`}
        >
          <div
            className={`rounded-2xl p-3  w-full break-all ${
              msg.to_teacher === 1
                ? "bg-secondary text-white"
                : "bg-green text-secondary"
            }`}
          >
            {msg.type === "text" && (
              <p style={{ wordBreak: "break-word" }}>{msg.msg}</p>
            )}
            {msg.type === "img" && (
              <img className="max-w-[160px] h-auto" src={msg.src} alt="msg" />
            )}
            {msg.type === "voice" && (
              <audio
                className="h-9 w-full "
                type="video/webm"
                controls
                src={msg.src}
              />
            )}
          </div>

          <img
            className="h-9 aspect-square border-2 border-bg overflow-hidden rounded-full object-cover min-h-[36px] min-w-[36px]"
            src={msg.to_teacher === 1 ? msg.student_img : msg.teacher_img}
            alt=""
          />
        </div>
        <span
          className={`flex  ${
            msg.to_teacher === 1 ? "justify-start" : " justify-end"
          }`}
        >
          {msg.date}
        </span>
      </div>
    </div>
  );
};

export default Message;
