const Analytics = ({ unreadedMsg }) => {
  return (
    <div className="mt-6 mb-2 flex items-align justify-center gap-6 ">
      <div className="bg-white w-3/4 md:w-full p-4 text-center rounded-xl">
        <h5 className="font-bold">Unreaded Messages</h5>
        <p className="mt-3">{unreadedMsg}</p>
      </div>
    </div>
  );
};

export default Analytics;
