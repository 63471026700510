import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useAuthDispatch } from "../../../../MainComponents/GlobalContext";

const StudentActivationAccount = () => {
  const AuthDispatch = useAuthDispatch();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);
  const { assistantID } = useParams("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  //!--------- add edit assistant -------

  const [
    ActivationProfileSuccess,
    ActivationProfileErrors,
    ActivationProfileLoading,
  ] = useAxios(
    process.env.REACT_APP_ACTIVATION_STUDENT_PROFILE_STUDENT_API,
    "POST",
    formFlag,
    formDependency,
    formValues,
    true
  );

  const onSubmit = (formData) => {
    setFormValues({ id: assistantID, ...formData });
    setFormFlag("EditAssistantPassword");
    setFormDependency(true);
  };

  function handleSignOut() {
    localStorage.removeItem("userData");
    AuthDispatch({ type: "setUserData", payload: null });
    navigate("/signin");
  }

  useEffect(() => {
    if (ActivationProfileSuccess) {
      handleSignOut();
    }
  }, [ActivationProfileSuccess]);

  useEffect(() => {
    if (ActivationProfileErrors) {
      setFormFlag("");
      setFormDependency(false);
    }
  }, [ActivationProfileErrors]);

  return (
    <div className="h-auto width flex flex-col items-center my-20">
      <h4 className="text-3xl font-bold">Activation My Account</h4>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-center items-start  bg-white p-16  rounded-3xl shadow-lg shadow-blue/50 w-3/4 my-6 gap-6"
      >
        <div className="w-full">
          <div className="relative flex flex-col justify-center items-start gap-2 ">
            <label htmlFor="activation_code">Activation Code</label>
            <input
              id="activation_code"
              className="signin-inputs signin-input w-full"
              type="text"
              placeholder="Activation Code"
              name="activation_code"
              autoComplete="on"
              {...register("activation_code", {
                required: true,
              })}
            />
          </div>
          {errors.activation_code && (
            <p className="text-blue-500 text-xl pt-4">
              {errors.activation_code.type === "required" &&
                "This field is required."}
            </p>
          )}
        </div>

        {/** Confirm Password  */}

        {/* <button className=" submit mt-6 w-full " type="submit">
          {ActivationProfileLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            "تعديل"
          )}
        </button> */}
        <input
          className=" submit max-w-60 mt-10"
          type="submit"
          value="Activate"
        />
      </form>
    </div>
  );
};

export default StudentActivationAccount;
