//!you may need to change record.id to record.key

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useTable from "../../../../MainComponents/Hooks/useTable.jsx";
import toast from "react-hot-toast";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { CSVLink } from "react-csv";

function ActivationCodes({ activation }) {
  const [submitGenerate, setSubmitGenerate] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [refetch, setRefetch] = useState(false);
  const table = useTable("activationCodes", "", "", refetch, activation);
  const [border, setBorder] = useState(false);
  const AuthState = useAuthState();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });
  let api = process.env.REACT_APP_GENERATE_ACTIVATION_CODES_API;
  const [generateCodesSuccess, generateCodesErrors, submitLoading] = useAxios(
    api,
    "POST",
    submitGenerate.flag,
    submitGenerate.dependency,
    submitGenerate.data,
    true
  );
  useEffect(() => {
    setBorder(false);
    reset();
  }, [activation]);

  const onSubmit = (data) => {
    setSubmitGenerate({
      flag: "generateCodesRequest",
      dependency: !submitGenerate.dependency,
      data: data,
    });
  };
  //const test={data:["0":"fvdcfdv","1":"dscfdsc","2":"cdscds"]}
  useEffect(() => {
    //! reset the form on success submit

    if (generateCodesSuccess) {
      setBorder(true);
      reset();
      setRefetch(!refetch);
    }
  }, [generateCodesSuccess]);

  async function copy(code) {
    try {
      await navigator.clipboard.writeText(code);
      toast.success("Copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
      toast.error("Failed to copy to clipboard");
    }
  }

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title={"Activation Codes | Nawar Academy"}></HelmetTags>{" "}
      <h2 className="w-full text-4xl font-bold md:text-center">
        Activation Codes
      </h2>
      {(AuthState?.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState?.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("code_add")
        )) && (
        <div className="form-codes-container my-20">
          <h2 className="my-20 text-center text-3xl font-semibold">
            Please fill the form to generate the activation codes
          </h2>
          <div className="form-codes flex w-full  items-start justify-center gap-20 lg:flex-col lg:items-center lg:justify-start">
            <div className="flex h-fit  w-1/2 md:w-full flex-col  items-center justify-start gap-6 rounded-3xl   p-10 shadow-lg shadow-red-200/30 ">
              <form
                method="post"
                onSubmit={handleSubmit(onSubmit)}
                className="shadow-l flex h-fit w-full  flex-col items-center justify-start gap-6 rounded-3xl bg-white p-10 shadow-blue/0 md:w-full "
              >
                <div className="first-row flex w-full items-start justify-between gap-6 md:flex-col md:justify-start">
                  {/** number of codes field */}

                  <div className=" flex w-1/2 flex-col items-end justify-center gap-2 md:w-full">
                    <label className="w-full truncate" htmlFor="count">
                      Number of Codes
                    </label>
                    <input
                      className="signin-inputs signin-input  w-full "
                      type="number"
                      min={1}
                      id="count"
                      placeholder="000"
                      name="count"
                      autoComplete="on"
                      {...register("count", {
                        required: true,
                        valueAsNumber: true,
                      })}
                    />

                    {errors.count && (
                      <p className="text-xs text-red-500 ">
                        {errors.count.type === "required" &&
                          "This field is required."}
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      generateCodesErrors &&
                        generateCodesErrors?.response?.data?.errors?.count && (
                          <p className="w-full text-end text-xs text-red-500  ">
                            {
                              generateCodesErrors?.response?.data?.errors
                                ?.count[0]
                            }
                          </p>
                        )
                    }
                  </div>

                  {/** length of code field */}

                  <div className=" flex w-1/2 flex-col items-end justify-center gap-2 md:w-full">
                    <label className="w-full truncate" htmlFor="length">
                      Length of Code
                    </label>
                    <input
                      className="signin-inputs signin-input w-full "
                      type="number"
                      min={1}
                      max={255}
                      id="length"
                      placeholder="000"
                      name="length"
                      autoComplete="on"
                      {...register("length", {
                        required: true,
                        valueAsNumber: true,
                        max: 255,
                        min: 1,
                      })}
                    />

                    {errors.length && (
                      <p className="text-xs text-red-500 ">
                        {errors.length.type === "required" &&
                          "This field is required."}
                        {errors.length.type === "max" &&
                          "Max letters count is 255 letter"}
                        {errors.length.type === "min" &&
                          "Please enter at least one letter"}
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      generateCodesErrors &&
                        generateCodesErrors?.response?.data?.errors?.length && (
                          <p className="w-full text-end text-xs text-red-500  ">
                            {
                              generateCodesErrors?.response?.data?.errors
                                ?.length[0]
                            }
                          </p>
                        )
                    }
                  </div>
                </div>

                <div className="second-row flex w-full items-start justify-between gap-6 md:flex-col md:justify-start">
                  {/** Price of codes field */}

                  {activation ? (
                    ""
                  ) : (
                    <div className=" flex w-1/2 flex-col items-end justify-center gap-2 md:w-full">
                      <label className="w-full truncate" htmlFor="price">
                        Price of Code
                      </label>
                      <input
                        className="signin-inputs w-full "
                        type="number"
                        min={1}
                        id="price"
                        placeholder="000"
                        name="price"
                        autoComplete="on"
                        {...register("price", {
                          required: true,
                          valueAsNumber: true,
                        })}
                      />

                      {errors.price && (
                        <p className="text-xs text-red-500 ">
                          {errors.price.type === "required" &&
                            "This field is required."}
                        </p>
                      )}
                      {
                        //!-------server errors -----

                        generateCodesErrors &&
                          generateCodesErrors?.response?.data?.errors
                            ?.price && (
                            <p className="w-full text-end text-xs text-red-500  ">
                              {
                                generateCodesErrors?.response?.data?.errors
                                  ?.price[0]
                              }
                            </p>
                          )
                      }
                    </div>
                  )}

                  {/** Type of codes field */}

                  <div
                    className={`flex flex-col items-end justify-center gap-2  ${
                      activation ? "w-full" : "w-1/2"
                    }  md:w-full`}
                  >
                    <label className="w-full truncate" htmlFor="type">
                      Type of Codes
                    </label>
                    <select
                      name="type"
                      id="type"
                      {...register("type", { required: true })}
                    >
                      <option value="char">Letters Only</option>
                      <option value="numbers">Numbers Only</option>
                      <option value="mixed">Mix</option>
                    </select>

                    {errors.type && (
                      <p className="text-xs text-red-500 ">
                        {errors.type.type === "required" &&
                          "This field is required."}
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      generateCodesErrors &&
                        generateCodesErrors?.response?.data?.errors?.type && (
                          <p className="w-full text-end text-xs text-red-500  ">
                            {
                              generateCodesErrors?.response?.data?.errors
                                ?.type[0]
                            }
                          </p>
                        )
                    }
                  </div>
                </div>

                {/** submit */}
                <button
                  disabled={!isValid || (submitLoading && submitGenerate.flag)}
                  className=" submit mt-6 w-full "
                  type="submit"
                >
                  {submitLoading && submitGenerate.flag ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    "Generate"
                  )}
                </button>
              </form>
            </div>
            {generateCodesSuccess?.data && (
              <div className="flex flex-col items-center gap-4">
                <button className="approve-btn rounded-[100px] border-2 border-secondary px-10 py-5 text-center text-2xl text-secondary transition-all duration-300 hover:scale-105 hover:text-white hover:bg-logoYellow hover:border-logoYellow active:scale-90">
                  <CSVLink
                    data={generateCodesSuccess?.data}
                    filename={`Generated Code Excel.csv`}
                  >
                    Export
                  </CSVLink>
                </button>
                <div
                  className={`codes flex h-auto max-h-[380px] w-[320px] flex-wrap items-center justify-center gap-7 overflow-y-auto p-4 md:w-[250px] ${
                    border ? "rounded-xl border-2 border-secondary" : ""
                  } `}
                >
                  {generateCodesSuccess?.data?.map((code) => (
                    <button
                      className="code-btn"
                      onClick={() => copy(code.code)}
                    >
                      {code.code}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default ActivationCodes;
