import {
  faEye,
  faEyeSlash,
  faSpinner,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReducer } from "react";
import { useEffect } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const AddNewAssistant = () => {
  const navigate = useNavigate();
  function reducer(state, action) {
    switch (action.type) {
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setSubmitAddEditAssistant": {
        return {
          ...state,
          submitAddEditAssistant: action.payload,
        };
      }

      case "setHide": {
        return {
          ...state,
          hide: !state.hide,
        };
      }

      case "setNoPermissions": {
        return {
          ...state,
          noPermissions: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    submitAddEditAssistant: {
      flag: "",
      dependency: false,
      data: {},
    },

    noPermissions: false,
  });
  //!--------- get all permissions request -------
  const [permissionsData, permissionsErrors] = useAxios(
    process.env.REACT_APP_PERMISSIONS_API,
    "GET",
    "GET",
    ""
  );
  //!--------- add assistant -------
  const [AddAssistantSuccess, AddAssistantErrors, AddAssistantLoading] =
    useAxios(
      process.env.REACT_APP_ADD_ASSISTANT_API,
      "POST",
      state.submitAddEditAssistant.flag,
      state.submitAddEditAssistant.dependency,
      state.submitAddEditAssistant.data,
      true
    );

  useEffect(() => {
    if (AddAssistantSuccess) {
      navigate(-1);
      reset();
    }
  }, [AddAssistantSuccess]);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (formData) => {
    const permissions = [];
    const info = [];
    const FormArray = Object.entries(formData);
    FormArray.map((entry) => {
      if (entry[1] === true) {
        permissions.push(entry[0]);
        return;
      } else if (entry[1] === false) {
        return;
      } else {
        info.push(entry);
      }
      return entry;
    });
    let infoObj = {};

    info.forEach((v) => {
      let key = v[0];
      let value = v[1];
      infoObj[key] = value;
    });

    const finalData = {
      ...infoObj,
      profile_pic: formData?.profile_pic[0] || null,
      permissions: permissions,
    };

    if (permissions.length > 0) {
      dispatch({
        type: "setSubmitAddEditAssistant",
        payload: {
          flag: "AddEditAssistant",
          dependency: !state.submitAddEditAssistant.dependency,
          data: finalData,
        },
      });
    } else {
      dispatch({
        type: "setNoPermissions",
        payload: true,
      });
      toast.error("Please, choose your permissions");
    }
  };
  return (
    <>
      <h2 className="my-20 text-center text-3xl font-semibold">
        Please fill in the data to add an assistant
      </h2>

      <form
        encType="multipart/form-data"
        method="post"
        onSubmit={handleSubmit(onSubmit)}
        className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl   p-16 shadow-lg shadow-blue-200/50"
      >
        <h2 className="-mb-3 flex w-full items-center justify-start  text-[17px] font-bold">
          <FontAwesomeIcon className="-ml-2 w-8" icon={faUserTie} />
          <span>Basic information</span>
        </h2>

        {/** Name filed */}
        <div className=" w-full ">
          <div className=" flex  w-full flex-col items-start  justify-center  gap-2">
            <label htmlFor="name">Name</label>
            <input
              className="signin-inputs signin-input w-full pl-4    "
              type="text"
              id="name"
              placeholder="Name"
              name="name"
              autoComplete="on"
              {...register("name", {
                required: true,
                //pattern: /^[A-Za-z]+$/,
                maxLength: 40,
                minLength: 3,
              })}
            />
            {errors.name && (
              <p className="text-xs text-blue-500  ">
                {errors.name.type === "required" &&
                  "Please fill out this field"}
                {errors.name.type === "pattern" && "Only English allowed"}
                {errors.name.type === "maxLength" && "Maximum characters is 40"}
                {errors.name.type === "minLength" &&
                  "Please type at least 3 letters"}
              </p>
            )}

            {
              //!-------server errors -----

              AddAssistantErrors &&
                AddAssistantErrors?.response?.data?.errors?.name && (
                  <p className="text-xs text-blue-500  ">
                    {AddAssistantErrors?.response?.data?.errors?.name[0]}
                  </p>
                )
            }
          </div>
        </div>

        {/** Username and mobile fields */}
        <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
          {/* Username field */}

          <div className="flex w-1/2 flex-col items-start justify-center gap-2 md:order-1 md:w-full">
            <label htmlFor="username">User name</label>
            <input
              className="signin-inputs signin-input w-full"
              type="text"
              id="username"
              placeholder="User name"
              name="username"
              autoComplete="on"
              {...register("username", {
                required: true,
                pattern: /^[A-Za-z0-9-_]*$/,
                maxLength: 20,
                minLength: 3,
              })}
            />
            {/* Username validation errors */}
            {errors.username && (
              <p className="text-xs text-blue-500">
                {errors.username.type === "required" &&
                  "Please, fill out this field."}
                {errors.username.type === "pattern" && "Only English allowed"}
                {errors.username.type === "maxLength" &&
                  "Maximum characters is 20"}
                {errors.username.type === "minLength" &&
                  "Please type at least 3 letters"}
              </p>
            )}
            {/* Server errors for username */}
            {AddAssistantErrors &&
              AddAssistantErrors?.response?.data?.errors?.username && (
                <p className="text-xs text-blue-500">
                  {AddAssistantErrors?.response?.data?.errors?.username[0]}
                </p>
              )}
          </div>
          {/**  mobile field */}

          <div className="w-1/2 md:order-2 md:w-full">
            <div className="flex w-full flex-col items-start justify-center gap-2">
              {/* Assistant Number */}
              <label htmlFor="mobile">Assistant number</label>
              <div className="relative w-full">
                <input
                  className="signin-inputs signin-input w-full"
                  type="text"
                  id="mobile"
                  placeholder="Assistant number"
                  name="mobile"
                  autoComplete="on"
                  {...register("mobile", {
                    required: true,
                    pattern: /^[\d]{11}/,
                    maxLength: 11,
                  })}
                />
                <FontAwesomeIcon
                  className="absolute bottom-1/2 right-4 w-5 h-5 translate-y-1/2"
                  icon={faWhatsapp}
                />
              </div>
            </div>
            {/* Mobile number validation errors */}
            {errors.mobile && (
              <p className="mt-2 text-xs text-blue-500">
                {errors.mobile.type === "required" && "Please, fill this input"}
                {errors.mobile.type === "pattern" &&
                  "Enter your 11-digit phone number"}
                {errors.mobile.type === "maxLength" &&
                  "Enter your 11-digit phone number"}
              </p>
            )}
            {/* Server errors for mobile number */}
            {AddAssistantErrors &&
              AddAssistantErrors?.response?.data?.errors?.mobile && (
                <p className="text-xs text-blue-500">
                  {AddAssistantErrors?.response?.data?.errors?.mobile[0]}
                </p>
              )}
          </div>
        </div>

        {/**  passwords fields */}
        <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
          {/** Password  */}
          <div className="w-1/2 md:order-1 md:w-full">
            <div className="relative flex flex-col items-start justify-center gap-2 ">
              <label htmlFor="password">Password</label>
              <div
                //!input password relative
                className="relative-hide relative h-auto w-full"
              >
                <input
                  id="password"
                  className="signin-inputs signin-input  w-full"
                  type={`${state.hide ? "text" : "password"}`}
                  placeholder="Password"
                  name="password"
                  autoComplete="on"
                  {...register("password", {
                    required: true,
                    maxLength: 25,
                    minLength: 6,
                  })}
                />

                <div
                  //!eye icons
                  onClick={() =>
                    dispatch({
                      type: "setHide",
                    })
                  }
                  className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                >
                  {state.hide ? (
                    <FontAwesomeIcon className="w-5 h-5" icon={faEye} />
                  ) : (
                    <FontAwesomeIcon className="h-5 w-5" icon={faEyeSlash} />
                  )}
                </div>
              </div>
            </div>
            {errors.password && (
              <p className="mt-2 text-xs text-blue-500">
                {errors.password.type === "required" &&
                  "Please fill out this field"}
                {errors.password.type === "maxLength" &&
                  "Maximum 25 characters"}
                {errors.password.type === "minLength" && "على الأقل 6 خانات"}
              </p>
            )}

            {
              //!-------server errors -----

              AddAssistantErrors &&
                AddAssistantErrors?.response?.data?.errors?.password && (
                  <p className="text-xs text-blue-500  ">
                    {AddAssistantErrors?.response?.data?.errors?.password[0]}
                  </p>
                )
            }
          </div>
          {/** Confirm Password  */}
          <div className="w-1/2 md:order-2 md:w-full">
            <div className="relative flex flex-col items-start justify-center gap-2 md:order-3 ">
              <label htmlFor="password_confirmation">Confirm password</label>

              <div
                //!input password relative
                className="relative-hide relative h-auto w-full"
              >
                <input
                  id="password_confirmation"
                  className="signin-inputs signin-input  w-full"
                  type={`${state.hide ? "text" : "password"}`}
                  placeholder="Confirm password"
                  name="password_confirmation"
                  autoComplete="on"
                  {...register("password_confirmation", {
                    required: true,
                    maxLength: 25,
                    minLength: 6,
                    validate: (value) => value === getValues("password"),
                  })}
                />

                <div
                  //!eye icons
                  onClick={() =>
                    dispatch({
                      type: "setHide",
                    })
                  }
                  className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                >
                  {state.hide ? (
                    <FontAwesomeIcon className="h-5 w-5" icon={faEye} />
                  ) : (
                    <FontAwesomeIcon className="h-5 w-5" icon={faEyeSlash} />
                  )}
                </div>
              </div>
            </div>
            {errors.password_confirmation && (
              <p className="mt-2 text-xs text-blue-500">
                {errors.password_confirmation.type === "required" &&
                  "Please fill out this field"}
                {errors.password_confirmation.type === "maxLength" &&
                  "Maximum characters is 25"}
                {errors.password_confirmation.type === "minLength" &&
                  "Please type at least 6 characters"}
                {errors.password_confirmation.type === "validate" &&
                  "Password does not match"}
              </p>
            )}

            {
              //!-------server errors -----

              AddAssistantErrors &&
                AddAssistantErrors?.response?.data?.errors
                  ?.password_confirmation && (
                  <p className="text-xs text-blue-500  ">
                    {
                      AddAssistantErrors?.response?.data?.errors
                        ?.password_confirmation[0]
                    }
                  </p>
                )
            }
          </div>
        </div>
        {/** User Image  */}
        <div className="flex w-full flex-col items-start gap-2">
          <label className="w-full  " htmlFor="profile_pic">
            Add profile picture
          </label>

          <input
            id="profile_pic"
            className="signin-inputs signin-input w-full"
            type="file"
            accept="image/jpg, image/jpeg, image/png, image/webp"
            name="profile_pic"
            {...register("profile_pic", {
              required: true,
              validate: (value) => !(value[0]?.size > 5000000),
            })}
          />

          {errors.profile_pic && (
            <p className="mt-2 text-xs text-blue-500">
              {errors.profile_pic.type === "required" && "Add profile picture"}
              {errors.profile_pic.type === "validate" && "Maximum size is 5 MB"}
            </p>
          )}
          {
            //!-------server errors -----

            AddAssistantErrors &&
              AddAssistantErrors?.response?.data?.errors?.profile_pic && (
                <p className="text-xs text-blue-500  ">
                  {AddAssistantErrors?.response?.data?.errors?.profile_pic[0]}
                </p>
              )
          }
        </div>

        <hr className="w-full border-[1px] border-blue" />
        <div className="student-permissions mt-0 flex h-fit w-full flex-col gap-7">
          {permissionsData?.data?.map((permission, i) => (
            <div
              key={i}
              className="student-permissions mt-0 flex h-fit w-full flex-col gap-7"
            >
              <h2 className="text-end w-full text-[17px] font-bold">
                <span>{permission.section} </span>
              </h2>
              <div className="input-label-container flex h-fit w-full flex-wrap-reverse items-center justify-evenly gap-10 rounded-3xl border border-blue p-10 ">
                {permission?.permissions.map((permission) => (
                  <div
                    key={permission.id}
                    className=" flex w-fit  items-center justify-center gap-16 md:w-full"
                  >
                    <div className="checkbox-wrapper">
                      <div className="cbx">
                        <input
                          id={permission.name}
                          className="signin-inputs signin-input w-full"
                          type="checkbox"
                          name={permission.name}
                          defaultChecked={permission.is_selected}
                          {...register(permission.name)}
                        />
                        <label htmlFor={permission.name}></label>
                        <svg
                          width="15"
                          height="14"
                          viewBox="0 0 15 14"
                          fill="none"
                        >
                          <path d="M2 8.36364L6.23077 12L13 2"></path>
                        </svg>
                      </div>

                      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                        <defs>
                          <filter id="goo-12">
                            <feGaussianBlur
                              in="SourceGraphic"
                              stdDeviation="4"
                              result="blur"
                            ></feGaussianBlur>
                            <feColorMatrix
                              in="blur"
                              mode="matrix"
                              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                              result="goo-12"
                            ></feColorMatrix>
                            <feBlend in="SourceGraphic" in2="goo-12"></feBlend>
                          </filter>
                        </defs>
                      </svg>
                    </div>

                    <label
                      className="w-fit cursor-pointer text-end"
                      htmlFor={permission.name}
                    >
                      {permission.public_name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <button
          // disabled={!isValid || (state.submitEditPhoto.flag && PhotoLoading)}
          className=" submit mt-6 w-full "
          type="submit"
        >
          {AddAssistantLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            "Add assistant"
          )}
        </button>
        {state.noPermissions && (
          <p className="mt-2 w-full text-center text-xs text-blue-500  ">
            Please select Assistant permission
          </p>
        )}
      </form>
    </>
  );
};

export default AddNewAssistant;
