import useTable from "../../../../MainComponents/Hooks/useTable";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useEffect, useState } from "react";
import { Loader } from "../../../../MainComponents";

function CenterViewExamTable() {
  const { examID, stage, groupID } = useParams();
  const [allData, setAllData] = useState([]);
  const [allExams, allExamsErrors, allExamsLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_TRIAL_EXAMS_TAB_API}`,
    "GET",
    "GET",
    ""
  );

  const table = useTable(
    "allCenterExams",
    "",
    "",
    allData,
    "",
    groupID,
    true,
    allData
  );

  useEffect(() => {
    if (allExams) {
      setAllData(allExams?.data?.filter((data) => +data.stage === +stage));
    }
  }, [allExams, stage]);

  if (allExamsLoading) return <Loader />;

  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title={`Center Exams | Nawar Academy`}></HelmetTags>

      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default CenterViewExamTable;
